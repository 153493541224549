export enum RAFCustomOperator {
    Default = 0,
    Contains = 1,
    NotContains = 2,
    BeginsWith = 3,
    NotBeginsWith = 4,
    EndsWith = 5,
    NotEndsWith = 6,
    Between = 7,
    NotBetween = 8,
    Equal = 9,
    NotEqual = 10,
    In = 11,
    NotIn = 12,
    LesserThan = 13,
    LesserThanOrEqual = 14,
    GreaterThan = 15,
    GreaterThanOrEqual = 16,
    IsEmpty = 17,
    IsNotEmpty = 18,
    IsNull = 19,
    IsNotNull = 20,
    IsNullOrEmpty = 21,
    IsNotNullAndEmpty = 22
}
export class RAFCustomFilter {
    id?: number;
    Condition?: string = "";
    Field?: string = "";
    Type?: string = "";
    Operator?: RAFCustomOperator;
    Value?: string[] = [];
    //Value?: string = "";
    Rules?: RAFCustomFilter[] = [];
    ShowAsParam?: boolean;
    Entity?: string;
}