import React, { createContext, PropsWithChildren } from 'react';
import { getFields } from '../helpers/AppHelper';
import { ConvertSystemName, IsNullOrWhiteSpace, wrapChildrenWith } from '../helpers/utils';
import { QueryAttributeJM } from '..//models/Common/QueryAttributeJM';
import { getAllAttributes } from '../helpers/AppHelper';
import { AttributeRelatedList } from '../models/Common/AttributeRelatedList';

interface IProps {
    moduleName?: string;
}
export const RAFAttributesContext = createContext<{ attributeRelatedList?: AttributeRelatedList, queryAttributes?: QueryAttributeJM[] }>({ attributeRelatedList: null, queryAttributes: null });

export default function RAFAttributeRelatedListProvider({ moduleName, children }: PropsWithChildren<IProps>) {
    const [entityAttributes, setEntityAttributes] = React.useState({
        attributeRelatedList: null,
        queryAttributes: null,
    });


    React.useEffect(() => {
        if (IsNullOrWhiteSpace(moduleName)) {
            return;
        }

        getAllAttributes(ConvertSystemName(moduleName)).then((attributeRelatedList) => {
            getFields(ConvertSystemName(moduleName)).then((queryAttributes) => {
                setEntityAttributes({
                    attributeRelatedList: attributeRelatedList,
                    queryAttributes: queryAttributes,
                })
            })

        });
    }, [moduleName]);

    let children1 = wrapChildrenWith(children, {
        attributeRelatedList: entityAttributes.attributeRelatedList,
        queryAttributes: entityAttributes.queryAttributes,
    });

    return (
        <RAFAttributesContext.Provider
            value={{ attributeRelatedList: entityAttributes.attributeRelatedList, queryAttributes: entityAttributes.queryAttributes }}
        >
            {children1 && children1}
        </RAFAttributesContext.Provider>
    );
}
