import { Browser } from '@syncfusion/ej2-base';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import axios from 'axios';
import Compressor from 'compressorjs';
import moment from 'moment';
import * as R from 'ramda';
import React, { ComponentType } from 'react';
import { msalInstance } from '../..';
import pluralize from '../../RAFComponents/components/pluralize/pluralize';
import * as repositoryActions from "../../RAFComponents/store/actions/repositoryActions";
import { ContentLibraryCurrentStatus } from '../../RAFMaster/helpers/RMutils';
import { RAFCustomFilter } from '../RAFViewPanels/RAFFilterColumn/RAFCustomFilter';
import { RAFOperator } from '../models/Common/QueryAttributeJM';
import {
    RAFChoiceListOption,
    RAFGropuedChoiceListOption
} from '../models/Common/RAFChoiceListSettings';
import { RAFDataType, RAFDataTypeDisplayName } from '../models/Common/RAFDataType';
import { RAFEntityBase } from '../models/Common/RAFEntityBase';
import { TenantRow } from '../models/Common/TenantRow';
import { LookUpRow } from '../models/CompositeTypes/LookUpRow';
import { Constants, ContentType, RAFHeaderNames, RAFLayout, SFColumnType, StorageKey } from './Constants';

//import { AppThunkActionAsync } from "@Store/index";

declare let process: any;

export function clone<T>(object: T): T {
    return JSON.parse(JSON.stringify(object));
}

export function setupCache() {
    localStorage.clear();
    sessionStorage.clear();
}

export function getJSONFromSessionStorage(key, includeDomainName?: boolean) {
    let retVal = sessionStorage.getItem(key);
    if (isNotNullAndUndefined(includeDomainName) && includeDomainName === true) {
        retVal = sessionStorage.getItem(msalInstance.currentTenantName + key);
    }
    if (isNotNullAndUndefined(retVal)) return JSON.parse(retVal);
    return null;
}

export function addJSONToSessionStorage(
    key?: string,
    includeDomainName?: boolean,
    value?: any
) {
    if (isNotNullAndUndefined(includeDomainName) && includeDomainName === true) {
        sessionStorage.setItem(
            msalInstance.currentTenantName + key,
            JSON.stringify(value)
        );
    } else {
        sessionStorage.setItem(key, JSON.stringify(value));
    }
}

export function removeFromSessionStorage(key) {
    sessionStorage.removeItem(key);
}

export const clearAllCaches = () => {
    //code to clear all caches
    if ('caches' in window) {
        caches.keys().then((names) => {
            for (let name of names) {
                console.log('names', names);
                caches.delete(name);
            }
        });
    }
};

export function clearAllCookies() {
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
    }
}

/*
export function getPromiseFromAction<T, V>(asyncActionCreator: AppThunkActionAsync<T, V>): Promise<V> {
    return (asyncActionCreator as any) as Promise<V>;
}
*/

/**
 * Is server prerendering by Node.js.
 * There can't be any DOM: window, document, etc.
 */
export function isNode(): boolean {
    return (
        typeof process === 'object' && process.versions && !!process.versions.node
    );
}

export function isObjectEmpty(obj): boolean {
    for (var key in obj) {
        if (obj.hasOwnProperty(key)) return false;
    }
    return true;
}

export function emptyForm(form: HTMLFormElement): void {
    const inputs = Array.from(form.querySelectorAll('input, select, textarea'));
    inputs.forEach((x) => {
        const inputType = x.getAttribute('type');
        if (inputType === 'checkbox' || inputType === 'radio') {
            (x as any).checked = false;
        } else {
            (x as any).value = '';
        }
    });
}

export function forceCast<T>(input: any): T {
    // ... do runtime checks here

    // @ts-ignore <-- forces TS compiler to compile this as-is
    return input;
}
export function isJson(item) {
    item = typeof item !== 'string' ? JSON.stringify(item) : item;

    try {
        item = JSON.parse(item);
    } catch (e) {
        return false;
    }

    if (typeof item === 'object' && item !== null) {
        return true;
    }

    return false;
}

export function hexToRGBA(hex, opacity): string {
    if (isNotNullAndUndefined(hex)) {
        let r = parseInt(hex.slice(1, 3), 16),
            g = parseInt(hex.slice(3, 5), 16),
            b = parseInt(hex.slice(5, 7), 16);

        // Check if the color is a light grey
        if (Math.abs(r - g) <= 10 && Math.abs(g - b) <= 10 && Math.abs(b - r) <= 10 && r >= 200) {
            // Return a different grey color for better visibility
            return `rgba(128, 128, 128, ${opacity})`;
        }

        return `rgba(${r}, ${g}, ${b}, ${opacity})`;
    }
}
export function currencyFormatting(amount, countryCode?): string {
    if (isNotNullAndUndefined(amount)) {
        const formattedAmount = amount.toLocaleString('en-US', { style: 'currency', currency: isNotNullAndUndefined(countryCode) ? countryCode : 'AUD' });
        return `${formattedAmount}`;
    }
    return '$0.00';
}

export function ConvertToType(objectToConvert, objectType): any {
    for (let key in objectToConvert) {
        if (objectToConvert[key] !== null && isJson(objectToConvert[key])) {
            if (objectToConvert[key] !== null && Array.isArray(objectToConvert[key])) {
                objectType[key] = [];

                //objectToConvert[key].map((item, i) => {
                //    objectType[key].push({});
                //    objectType[key][i] = (ConvertToType(item, objectType[key][i]));

                //})

                objectToConvert[key].forEach((item, i) => {
                    objectType[key].push({});
                    objectType[key][i] = ConvertToType(item, objectType[key][i]);
                });
            } else {
                objectType[key] = {};
                objectType[key] = ConvertToType(
                    JSON.parse(objectToConvert[key]),
                    objectType[key]
                );
            }
        } else if (
            objectToConvert[key] !== null &&
            Array.isArray(objectToConvert[key])
        ) {
            objectType[key] = [];
            //objectToConvert[key].map((item, i) => {
            //    objectType[key].push({});
            //    objectType[key][i] = (ConvertToType(item, objectType[key][i]));

            //})
            objectToConvert[key].forEach((item, i) => {
                objectType[key].push({});
                objectType[key][i] = ConvertToType(item, objectType[key][i]);
            });
        } else {
            objectType[key] = [];
            objectType[key] = objectToConvert[key];
        }
    }
    return objectType;
}

export function ConvertStringToType(objectToConvert, objectType): any {
    for (let key in objectToConvert) {
        if (
            objectToConvert[key] !== null &&
            objectToConvert[key] instanceof RAFEntityBase
        ) {
            objectType[key] = [];
            objectType[key] = ConvertToType(
                JSON.parse(objectToConvert[key]) as RAFEntityBase,
                objectType[key]
            );
        } else if (
            objectToConvert[key] !== null &&
            Array.isArray(objectToConvert[key])
        ) {
            objectType[key] = [];

            //objectToConvert[key].map((item, i) => {
            //    objectType[key].push({});
            //    objectType[key][i] = (ConvertToType(item, objectType[key][i]));

            //})

            objectToConvert[key].forEach((item, i) => {
                objectType[key].push({});
                objectType[key][i] = ConvertToType(item, objectType[key][i]);
            });
        } else {
            objectType[key] = [];
            objectType[key] = objectToConvert[key];
        }
    }
    return objectType;
}

export function ConvertSelectToLookupRow(label, value): any {
    if (value !== null) {
        if (Array.isArray(value)) {
            let lookUpRowList: LookUpRow[] = [];
            for (var i = 0; i < value.length; i++) {
                let lookUpRow: LookUpRow = new LookUpRow();
                lookUpRow.Value = value[i].label;
                lookUpRow.UID = value[i].value;
                lookUpRowList.push(lookUpRow);
            }
            return lookUpRowList;
        } else {
            let lookUpRow: LookUpRow = new LookUpRow();
            if (label !== null) {
                lookUpRow.UID = value;
                lookUpRow.Value = label;
                return lookUpRow;
            }
        }
    } else {
        return [];
    }
}
export function ConvertLookupRowArrayToChoiceList(
    lookUpRows: LookUpRow[]
): RAFChoiceListOption[] {
    let choiceList: RAFChoiceListOption[] = [];
    if (lookUpRows !== null) {
        for (var i = 0; i < lookUpRows.length; i++) {
            choiceList.push({ label: lookUpRows[i].Value, value: lookUpRows[i].UID });
        }
    }
    return choiceList;
}

export function ConvertLookupRowDetail(value: LookUpRow[]): any {
    let retVal = value.map((item) => {
        return `${item.Value}`;
    });
    return retVal;
}

/*
EntityDialog.prototype.getSaveEntity = function () {
    const entity = new Object();
    if (this.propertyGrid !== null) {
        this.propertyGrid.save(entity);
    }
    if (this.isEditMode()) {
        const idField = this.getIdProperty();
        if (idField !== null && entity[idField] === null) {
            entity[idField] = this.get_entityId();
        }
    }
    return entity;
};
EntityDialog.prototype.getSaveRequest = function () {
    const entity = this.getSaveEntity();
    const req = {};
    req.Entity = entity;
    if (this.isEditMode()) {
        const idField = this.getIdProperty();
        if (idField !== null) {
            req.EntityId = this.get_entityId();
        }
    }
    if (this.localizationPendingValue !== null) {
        req.Localizations = this.getPendingLocalizations();
    }
    return req;
};
*/
export function getSaveRequest(entity: any, entityId: any): any {
    const req = {
        Entity: null,
        EntityId: null,
        Localizations: null,
    };
    req.Entity = entity;
    req.EntityId = entityId;
    return req;
}

export function isEmptyOrNull(s: string) {
    return s == null || s.length === 0;
    // return s === null || s.length === 0;
}

export function IsNullOrWhiteSpace(s: string) {
    return !(
        s !== null &&
        s !== undefined &&
        s !== 'undefined' &&
        //s.trim() !== ''
        s !== '' &&
        s !== ' '
    );
}

export function IsNotNullOrWhiteSpace(s: any) {
    return (
        s !== null &&
        s !== undefined &&
        s !== 'undefined' &&
        //s.trim() !== ''
        s !== '' &&
        s !== "" && s !== " " &&
        s !== ' ' && s !== "null"
    );
}


export function isNullOrUndefined(s: any) {
    return s == null || s === undefined;
    // return s === null || s === undefined;
}

export function isNotNullAndUndefined(s: any) {
    return s !== null && s !== undefined;
}

export function isNotEmptyArray(s: any) {
    if (isNotNullAndUndefined(s) && isArray(s) && s.length > 0) {
        return true;
    }
    return false;
}

export function isEmptyArray(s: any) {
    if (isNotNullAndUndefined(s) && isArray(s) && s.length === 0) {
        return true;
    }
    return false;
}

export function getDistinctValuesFromArrayByField(s: any[], variable: string) {
    let unique = null;
    if (isNotEmptyArray) {
        unique = s.map(item => item[variable])
            .filter((value, index, self) => self.indexOf(value) === index);
    }
    return unique;
}

export const checkIsSearchTextMatch = (searchText: string, value: string) => {
    if (IsNotNullOrWhiteSpace(searchText) && IsNotNullOrWhiteSpace(value)) {
        const searchWords = searchText.toLowerCase().split(/\s+/);
        const valueWords = value.toLowerCase().split(/\s+/);

        const startsWithMatch = searchWords.some(searchWord =>
            valueWords.some(valueWords => valueWords.startsWith(searchWord))
        );

        const containsMatch = value.toLowerCase().includes(searchText.toLowerCase());

        // Prioritize startsWithMatch but also include containsMatch
        const isMatch = startsWithMatch || containsMatch;
        return isMatch;
    } else {
        return false;
    }
};

export function joinStringArray(value: string[], separator: string = ' ') {
    let displayText;
    if (isNotEmptyArray(value)) {
        value.forEach(text => {
            if (IsNotNullOrWhiteSpace(text)) {
                if (isNotNullAndUndefined(displayText)) {
                    if (isNullOrUndefined(separator)) {
                        displayText = `${displayText}${text}`;
                    } else {
                        displayText = `${displayText}${separator}${text}`;
                    }
                } else {
                    displayText = text;
                }
            }
        });
    }

    return displayText;
}

/*
declare global {
    interface Array<T> {
        filterAnyCondition(key: string, sortByArgs: boolean, ...args: string[]): this;
    }
}
*/

/*eslint no-extend-native: ["error", { "exceptions": ["Array"] }]*/
/*
Array.prototype.filterAnyCondition = function (key: string, sortByArgs: boolean, ...args: string[]) {

    const result = [];
    if (key !== null && key !== undefined && key.length > 0 && args !== null && args !== undefined && args.length > 0) {
        if (sortByArgs) {
            args.forEach(arg => {
                result.push(this.filter(x => arg.includes(x[key])));
            });
        }
        else {
            result = this.filter(x => args.includes(x[key]));
        }
    }
    else {
        result = this;
    }
    return result;

};
*/

export function groupedOptions(
    response,
    displayField,
    valueField,
    headerField
) {
    const GroupHeader = [];

    //response.Entities.map((opt) => {
    //    if (GroupHeader.indexOf(opt[headerField]) === -1) {
    //        GroupHeader.push(opt[headerField])
    //    }
    //})

    response.Entities.forEach((opt) => {
        if (GroupHeader.indexOf(opt[headerField]) === -1) {
            GroupHeader.push(opt[headerField]);
        }
    });

    let groupheader = GroupHeader.map((header) => {
        return {
            label: header,
        };
    });
    let groupOptions = groupheader.map((groupLabel) => {
        let newoptions = response.Entities.forEach((options) => {
            if (groupLabel.label === options[headerField]) {
                return {
                    value: options[valueField],
                    label: options[displayField],
                };
            }
        });
        const options: (string | null)[] = newoptions;
        const filterdoption: string[] = options.filter(
            (f) => f !== undefined && f !== null
        ) as any;
        return { label: groupLabel.label, options: filterdoption };
    });
    return groupOptions;
}

export function groupBy(objectArray, property) {
    return objectArray.reduce((acc, obj) => {
        const key = obj[property];
        if (!acc[key]) {
            acc[key] = [];
        }
        // Add object to list for given key's value
        acc[key].push(obj);
        return acc;
    }, {});
}

export function getGroupedOptionsForSelect(
    groupByArray,
    displayField,
    valueField
) {
    let retVal: RAFGropuedChoiceListOption[] = [];
    for (let key in groupByArray) {
        let groupByValues = groupByArray[key];
        let filterdoption: RAFChoiceListOption[] = groupByValues.map((options) => {
            return {
                value: options[valueField],
                label: options[displayField],
            };
        });
        retVal.push({ label: key, options: filterdoption });
    }
    return retVal;
}

export function Paging(current, total, limit) {
    const list = [];
    const pageLimit = limit;
    let upperLimit, lowerLimit;
    let currentPage = (lowerLimit = upperLimit = Math.min(current, total));

    for (var b = 1; b < pageLimit && b < total;) {
        if (lowerLimit > 1) {
            lowerLimit--;
            b++;
        }
        if (b < pageLimit && upperLimit < total) {
            upperLimit++;
            b++;
        }
    }

    for (var i = lowerLimit; i <= upperLimit; i++) {
        if (i === currentPage) {
            list.push(i);
        } else {
            list.push(i);
        }
    }
    return list;
}

export function GetFieldValue(objectToGet, fieldName, index) {
    let retVal = null;
    if (objectToGet !== null && objectToGet !== undefined) {
        if (fieldName.indexOf('.') > 0) {
            let firstString = fieldName.substring(0, fieldName.indexOf('.'));
            let secondString = fieldName.substring(fieldName.indexOf('.') + 1);
            let objData = objectToGet[firstString];
            if (secondString.indexOf('.') > 0) {
                retVal = GetFieldValue(objData, secondString, index);
            } else {
                if (objData !== null && objData !== undefined && isJson(objData)) {
                    if (
                        objData !== null &&
                        objData !== undefined &&
                        Array.isArray(objData)
                    ) {
                        if (
                            objData[index] !== null &&
                            objData[index] !== undefined &&
                            objData[index][secondString] !== null &&
                            objData[index][secondString] !== undefined
                        ) {
                            //retVal = JSON.parse(objData[index])[secondString];
                            retVal = objData[index][secondString];
                        }
                    } else {
                        if (objData !== null && objData !== undefined) {
                            //retVal = JSON.parse(objData)[secondString];
                            retVal = objData[secondString];
                        }
                    }
                } else if (
                    objData !== null &&
                    objData !== undefined &&
                    Array.isArray(objData)
                ) {
                    if (
                        objData[index] !== null &&
                        objData[index] !== undefined &&
                        objData[index][secondString] !== null &&
                        objData[index][secondString] !== undefined
                    ) {
                        //retVal = JSON.parse(objData[index])[secondString];
                        retVal = objData[index][secondString];
                    }
                } else {
                    if (objData !== null && objData !== undefined) {
                        retVal = objData[secondString];
                    }
                }
            }
        } else {
            retVal = objectToGet[fieldName];
        }
    }
    return retVal;
}

export class Guid {
    static newGuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
            /[xy]/g,
            function (c) {
                const r = (Math.random() * 16) | 0,
                    //v = (c === 'x') ? r : (r && 0x3 || 0x8);
                    v = c === 'x' ? r : (r & 0x3) | 0x8;
                return v.toString(16);
            }
        );
    }
    static isGuid(str) {
        const regexExp =
            /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
        return regexExp.test(str);
    }
}

//export const RAFLayoutContext = React.createContext(RAFLayout.OneColumnLayout);

export const propertyOf = <TObj>(name: keyof TObj) => name;

export function formatValue(
    inVal,
    {
        inDateFormat = null,
        outDateFormat = 'DD/MM/YYYY',
        booleanTrueText = 'Yes',
        booleanFalseText = 'No',
    } = {}
) {
    //console.log('isdate', inVal, moment(inVal, inDateFormat, true).isValid());
    let outVal = inVal;
    if (isNotNullAndUndefined(inVal)) {
        if (isBoolean(inVal)) {
            if (inVal === true) outVal = booleanTrueText;
            else outVal = booleanFalseText;
        }
        //else if (!isNaN(Date.parse(inVal))) {
        else if (moment(inVal, inDateFormat, true).isValid()) {
            if (inDateFormat === moment.ISO_8601) {
                outVal = moment(new Date(inVal + 'Z')).format(outDateFormat);
            } else {
                outVal = moment(inVal, inDateFormat).format(outDateFormat);
            }
        }
    }
    return outVal;
}

export function RemoveParamProperty(
    inVal: RAFCustomFilter,
    prop: keyof RAFCustomFilter
): RAFCustomFilter {
    delete inVal[prop];
    if (isNotNullAndUndefined(inVal.Rules)) {
        inVal.Rules = inVal.Rules.map((object) => {
            object = RemoveParamProperty(object, prop);
            return object;
        });
    }
    return inVal;
}

export function mapOrder(a, order, key) {
    const map = order.reduce((r, v, i) => ((r[v] = i), r), {});
    return a.sort((a, b) => map[a[key]] - map[b[key]]);
}

export function sortItem(items: any[], sortBy: string, action?: 'ascending' | 'descending') { //default 
    if (isNotNullAndUndefined(sortBy) && isNotNullAndUndefined(items) && items.length > 0) {
        if (action === 'descending') {
            items.sort((a, b) => (a[sortBy] < b[sortBy]) ? 1 : -1);
            return (items);
        } else {
            items.sort((a, b) => (a[sortBy] > b[sortBy]) ? 1 : -1);
            return (items);
        }
    } else {
        return (items);
    }
}

export function convertDateArrayToStringArray(
    inVal: Date[],
    format: string
): string[] {
    let retVal: string[] = [];
    if (
        isNotNullAndUndefined(inVal) &&
        inVal.length > 0 &&
        isNotNullAndUndefined(inVal[0])
    ) {
        retVal.push(moment(inVal[0]).format(format));
    } else {
        retVal.push(undefined);
    }
    if (
        isNotNullAndUndefined(inVal) &&
        inVal.length > 1 &&
        isNotNullAndUndefined(inVal[1])
    ) {
        retVal.push(moment(inVal[1]).format(format));
    } else {
        retVal.push(undefined);
    }
    return retVal;
}

export function convertStringArrayToDateArray(
    inVal: string[],
    format: string
): Date[] {
    let retVal: Date[] = [];
    if (
        isNotNullAndUndefined(inVal) &&
        inVal.length > 0 &&
        isNotNullAndUndefined(inVal[0])
    ) {
        retVal.push(moment(inVal[0], format, true).toDate());
    } else {
        retVal.push(undefined);
    }
    if (
        isNotNullAndUndefined(inVal) &&
        inVal.length > 1 &&
        isNotNullAndUndefined(inVal[1])
    ) {
        retVal.push(moment(inVal[1], format, true).toDate());
    } else {
        retVal.push(undefined);
    }
    return retVal;
}

export function getSFColumnTypeByRAFDatatype(
    rafDataType: string
): SFColumnType {
    let outVal = SFColumnType.string;
    switch (rafDataType) {
        case RAFDataType.Boolean:
            outVal = SFColumnType.boolean;
            break;
        case RAFDataType.Date:
            outVal = SFColumnType.date;
            break;
        case RAFDataType.Number:
            outVal = SFColumnType.number;
            break;
        // case RAFDataType.Dropdown:
        //     outVal = SFColumnType.dropdown;
        //     break;
        // there is no type dropdown in syncfusion ColumnDirective.. this SFColumnType used only for filterTemplate
    }
    return outVal;
}

export function getRAFDatatypeBySFColumnType(
    rafDataType: string
): RAFDataType {
    let outVal = RAFDataType.Text;
    switch (rafDataType) {
        case SFColumnType.boolean:
            outVal = RAFDataType.Boolean;
            break;
        case SFColumnType.date:
            outVal = RAFDataType.Date;
            break;
        case SFColumnType.number:
            outVal = RAFDataType.Number;
            break;
        case SFColumnType.dropdown:
            outVal = RAFDataType.Dropdown;
            break;
    }
    return outVal;
}
export function getSFColumnTypeByRAFDatatypeForFilter(
    rafDataType: string
): SFColumnType {
    let outVal = SFColumnType.string;
    switch (rafDataType) {
        case RAFDataType.Boolean:
            outVal = SFColumnType.boolean;
            break;
        case RAFDataType.Date:
            outVal = SFColumnType.date;
            break;
        case RAFDataType.Number:
            outVal = SFColumnType.number;
            break;
    }
    return outVal;
}

export interface IDialogProps {
    onClose?: () => void;
    onSave?: (entityId?: string, objectName?: string) => void;
    isActive?: boolean;
}

export interface RAFFormComponentProps {
    initialValues?: object;
}

export function swapArray(Array: any, Swap1: number, Swap2: number): any {
    const temp = Array[Swap1];
    Array[Swap1] = Array[Swap2];
    Array[Swap2] = temp;
    return Array;
}

export function cleanUndefinedToNull(obj: any) {
    if (obj === undefined) {
        return null;
    } else if (typeof obj !== 'object') {
        return obj;
    } else {
        if (obj instanceof Array) {
            for (let key of obj) {
                cleanUndefinedToNull(key);
            }
        } else {
            for (let key in obj) {
                if (obj[key] === undefined) {
                    obj[key] = null;
                } else if (typeof obj[key] === 'object') {
                    cleanUndefinedToNull(obj[key]);
                }
            }
        }
    }

    return obj;
}

export function ConvertSystemName(inputVal: string) {
    let retVal;
    if (isNotNullAndUndefined(inputVal)) {
        retVal = inputVal.toLowerCase().replace(/^[ ]+|[ ]+$/g, '');
        retVal = retVal.replace(/[^a-zA-Z0-9]/g, '_');
        //retVal = retVal.replace(/\s/g, '_');
        return retVal;
    } else {
        return '';
    }
}

export function ConvertCollectionName(inputVal: string) {
    if (isNotNullAndUndefined(inputVal)) {
        if (inputVal.toLowerCase().endsWith('library')
            || inputVal.toLowerCase().endsWith('information') || inputVal.toLowerCase().endsWith('monitor')) { //temp fix only
            return inputVal;
        } else {
            const collectionName = pluralize.plural(inputVal);
            return collectionName;
        }
    } else {
        return '';
    }
}

export function ReplaceEmptySpace(inputVal: string) {
    let retVal;
    if (isNotNullAndUndefined(inputVal)) {
        retVal = inputVal.toLowerCase().replace(/^[ ]+|[ ]+$/g, '');
        retVal = retVal.replace(/\s/g, '');
        return retVal;
    } else {
        return '';
    }
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function ConvertToPascal(inputVal: string) {
    let i, frags = isNotNullAndUndefined(inputVal) ? inputVal.split('_') : [];
    for (i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
}

export function ConvertToURLValue(inputVal: string) { //ReplaceEmptySpace & ConvertToPascal
    if (isNotNullAndUndefined(inputVal)) {
        let value = inputVal.replace(/^[ ]+|[ ]+$/g, '');
        value = value.replace(/\s/g, '');

        let i, frags = isNotNullAndUndefined(value) ? value.split('_') : [];
        for (i = 0; i < frags.length; i++) {
            frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
        }
        return frags.join('');
    } else {
        return '';
    }
}

export function ConvertToElementID(inputVal: string) {
    let retVal;
    if (!IsNullOrWhiteSpace(inputVal)) {
        retVal = inputVal.replace(/[^A-Z0-9]/gi, "");
        return retVal;
    } else {
        return retVal;
    }
}

export function getQueryString(key: string) {
    //let query = useQuery();
    //return query.get(key);

    // let query = new URLSearchParams(window.location.search);
    // return query.get(key);


    let params = new URLSearchParams(window.location.search);
    let entries = Array.from(params.entries());
    let foundEntry = entries.find(([k]) => k.toLowerCase() === key.toLowerCase());
    return foundEntry && foundEntry[1];
}

export function getSubDomain(): string {
    //console.log('getSubDomain window.location.host', window.location.host);
    //let subdomain = window.location.host.split('.')[0];
    //if (subdomain.indexOf(':') >= 0) {
    //    subdomain = subdomain.substring(0, subdomain.indexOf(':'));
    //}

    let subdomain: string = null;
    // const windowLocationHost: string = window.location.host;
    // const REACT_APP_ROOTSITE: string = isNotNullAndUndefined(
    //     process.env.REACT_APP_ROOTSITE
    // )
    //     ? process.env.REACT_APP_ROOTSITE
    //     : null;
    // if (isNotNullAndUndefined(REACT_APP_ROOTSITE)) {
    //     if (windowLocationHost.indexOf(REACT_APP_ROOTSITE) > 0) {
    //         subdomain = windowLocationHost.substring(
    //             0,
    //             windowLocationHost.indexOf(REACT_APP_ROOTSITE) - 1
    //         );
    //     }
    // }
    //console.log('getSubDomain subdomain', subdomain);
    return subdomain; //window.location.host.split('.')[0];
}

export function getPureSubDomainOrHint(): string {
    let subdomain = '';
    /*const windowLocationHost: string = window.location.host;
    const regexParse = new RegExp('[a-z\-0-9]{2,63}\.[a-z\.]{2,5}$');
    const urlParts = regexParse.exec(windowLocationHost);
    if (urlParts !== null) {
        subdomain = windowLocationHost.replace(urlParts[0], '').slice(0, -1);
    }
    else {
        subdomain = windowLocationHost;
    }*/

    const tenantStorage: TenantRow = JSON.parse(
        window.localStorage.getItem(StorageKey.currentTenant)
    );
    if (isNotNullAndUndefined(tenantStorage)) {
        subdomain = tenantStorage.SubDomain;
    } else {
        /*const domainHint = getQueryString("domainHint");
        if (!IsNullOrWhiteSpace(domainHint)) {
            subdomain = domainHint;
        }*/
        // const REACT_APP_SUBDOMAIN: string = isNotNullAndUndefined(
        //     process.env.REACT_APP_SUBDOMAIN
        // )
        //     ? process.env.REACT_APP_SUBDOMAIN
        //     : null;
        // if (!IsNullOrWhiteSpace(REACT_APP_SUBDOMAIN)) {
        //     subdomain = REACT_APP_SUBDOMAIN;
        // }
    }
    return subdomain;
}

export function getLogoURL(): string {
    let productLogo = `https://workesio-static.s3.ap-southeast-2.amazonaws.com/clientlogo/company_logo.png`;
    let subdomain = '';

    const tenantStorage: TenantRow = JSON.parse(
        window.localStorage.getItem(StorageKey.currentTenant)
    );
    if (isNotNullAndUndefined(tenantStorage)) {
        subdomain = tenantStorage.SubDomain;
        productLogo = `https://workesio-static.s3.ap-southeast-2.amazonaws.com/clientlogo/${subdomain}_logo.png`;
    }

    return productLogo;
}

export const hasSSO = (subDomain: string) => {
    return new Promise<boolean>((resolve) => {
        let retVal = false;
        resolve(retVal);
    });
};

export function nameToInitials(inputVal?: string) {
    let retVal;
    retVal = inputVal.match(/\b(\w)/g).join('');
    return retVal;
}

export function getDate(d: Date, format: string): string {
    let dateString = '';
    if (d !== null) {
        if (moment(d).isSame(moment(new Date()).add(1, 'day'), 'day')) {
            dateString = 'Tomorrow';
        } else if (moment(d).isSame(moment(new Date()).subtract(1, 'day'), 'day')) {
            dateString = 'Yesterday';
        } else if (moment(d).isSame(new Date(), 'day')) {
            dateString = 'Today';
        } else {
            dateString = moment(d).format(format);
        }
    }
    return dateString;
}

export function ShowElement(target: string | HTMLElement) {
    let targetDiv = null;
    if (target instanceof HTMLElement) {
        targetDiv = target;
    } else {
        targetDiv = document.querySelector(target);
    }

    if (targetDiv !== null) {
        targetDiv.classList.remove('hidden');
    }
    else {
        //trying 1 more time after timeout
        setTimeout(() => {
            if (target instanceof HTMLElement) {
                targetDiv = target;
            } else {
                targetDiv = document.querySelector(target);
            }
            if (targetDiv !== null) {
                targetDiv.classList.remove('hidden');
            }
        }, 300);
    }
}

export function HideElement(target: string | HTMLElement) {
    let targetDiv = null;
    if (target instanceof HTMLElement) {
        targetDiv = target;
    } else {
        targetDiv = document.querySelector(target);
    }

    if (targetDiv !== null) {
        targetDiv.classList.add('hidden');
    }
    else {
        //trying 1 more time after timeout
        setTimeout(() => {
            if (target instanceof HTMLElement) {
                targetDiv = target;
            } else {
                targetDiv = document.querySelector(target);
            }
            if (targetDiv !== null) {
                targetDiv.classList.add('hidden');
            }
        }, 300);
    }
}

export function ShowElementAllOccurence(selectors: string) {
    setTimeout(() => {
        let divComps = document.querySelectorAll(selectors);
        if (isNotNullAndUndefined(divComps)) {
            divComps.forEach((x) => {
                x.classList.remove('hidden');
            });
        }
    }, 100);
}

export function HideElementAllOccurence(selectors: string) {
    setTimeout(() => {
        let divComps = document.querySelectorAll(selectors);
        if (isNotNullAndUndefined(divComps)) {
            divComps.forEach((x) => {
                x.classList.add('hidden');
            });
        }
    }, 100);
}

export function AddClassToElement(selectors: string, className: string) {
    setTimeout(() => {
        let divComps = document.querySelectorAll(selectors);
        if (isNotNullAndUndefined(divComps)) {
            divComps.forEach((x) => {
                x.classList.add(className);
            });
        }
    }, 100);
}

export function RemoveClassFromElement(selectors: string, className: string) {
    setTimeout(() => {
        let divComps = document.querySelectorAll(selectors);
        if (isNotNullAndUndefined(divComps)) {
            divComps.forEach((x) => {
                x.classList.remove(className);
            });
        }
    }, 100);
}

export function deepEqual(object1, object2) {
    if (object1 == null && object2 == null) {
        // if (object1 === null && object2 === null) {
        return true;
    }
    if (object1 == null && object2 !== null) {
        // if (object1 === null && object2 !== null) {
        return false;
    }
    if (object2 == null && object1 !== null) {
        // if (object2 === null && object1 !== null) {
        return false;
    }
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        const val1 = object1[key];
        const val2 = object2[key];
        const areObjects = isObject(val1) && isObject(val2);
        if (
            (areObjects && !deepEqual(val1, val2)) ||
            (!areObjects && val1 !== val2)
        ) {
            return false;
        }
    }

    return true;
}

function isObject(object) {
    return object !== null && typeof object === 'object';
}

export function endsWith(str, suffix) {
    return str.indexOf(suffix, str.length - suffix.length) !== -1;
}

export function setZeroHours(d?: Date): Date {
    if (isNotNullAndUndefined(d)) {
        //console.log('e.value utc 2', new Date(e.value.setHours(0, 0, 0, 0,)));
        return new Date(d.setHours(0, 0, 0, 0));
    }
    return d;
}

export function convertUTCDateToLocalTimezone(inputDate?: Date): Date {
    //inputDate is UTC date of format '2016-08-25T00:00:00' to javascript local datetime
    if (isNotNullAndUndefined(inputDate)) {
        const date = new Date(inputDate.toString().replace('Z', ''));
        const userTimezoneOffset = date.getTimezoneOffset() * 60000;
        return new Date(date.getTime() - userTimezoneOffset);
    }
    return inputDate;
}

export function convertUTCDateStringToLocalTimezone(inputDate?: string): Date | string {
    //inputDate is UTC date of format '2016-08-25T00:00:00' to javascript local datetime
    if (isNotNullAndUndefined(inputDate)) {
        const date = new Date(inputDate.toString().replace('Z', ''));
        const userTimezoneOffset = date.getTimezoneOffset() * 60000;
        return new Date(date.getTime() - userTimezoneOffset);
    }
    return inputDate;
}

export const getUTCDateValue = (inputValue, format?) => {
    if (IsNotNullOrWhiteSpace(inputValue)) {
        const formValue = inputValue;
        const momentObj = moment.parseZone(formValue);
        //const isUtc = momentObj.utcOffset() === 0;
        const isLocal = momentObj.utcOffset() !== 0;

        if (isLocal === true) {
            if (isNotNullAndUndefined(format)) {
                return moment(formValue).format(format);
            }
            else {
                return moment(formValue).toDate();
            }
        } else {
            let retVal: any = convertUTCDateToLocalTimezone(inputValue);
            if (IsNotNullOrWhiteSpace(inputValue)) {
                if (
                    moment(retVal, moment.ISO_8601, true).isValid() &&
                    !isDate(retVal)
                ) {
                    if (isNotNullAndUndefined(format)) {
                        retVal = moment(retVal).format(format);
                    }
                    else {
                        retVal = moment(new Date(retVal + "Z")).toDate();
                    }
                }
                else {
                    if (isNotNullAndUndefined(format)) {
                        retVal = moment(retVal).format(format);
                    }
                }
            }
            return retVal;
        }
    } else {
        return null;
    }
};


export function getFileExtension(filename) {
    if (IsNullOrWhiteSpace(filename)) return null;
    // get file extension
    const extension = filename.split('.').pop().toLowerCase();
    return extension;
}

export function combineFilters(...args: RAFCustomFilter[]): RAFCustomFilter {
    let outVal: RAFCustomFilter = {};
    //console.log('args', args, args.length);
    if (isNotNullAndUndefined(args) && args.length > 0) {
        if (args.length === 1) return args[0];
        let rules2: RAFCustomFilter[] = [];

        for (var i = 0; i < args.length; i++) {
            const objArg: RAFCustomFilter = args[i];
            if (isNotNullAndUndefined(objArg)) {
                if (isNotNullAndUndefined(objArg.Field)) {
                    rules2.push(objArg);
                } else if (
                    isNotNullAndUndefined(objArg.Rules) &&
                    objArg.Rules.length > 0
                ) {
                    rules2.push(objArg);
                }
            }
        }
        if (isNotNullAndUndefined(rules2) && rules2.length > 0) {
            outVal.Condition = RAFOperator.AndCondition;
            outVal.Rules = rules2;
        }
    }
    return outVal;
}

/**
 * Returns a new object with only few attributes of the original object.
 * Note: the attributes/properties will still be bound to the old object.
 *
 * @param  {Object} object     The object.
 * @param  {Array}             Array of selected attributes.
 * @return {Object}            New object with only the selected attributes.
 */
export function objectWithOnly(object, attrs) {
    let newObject = {};

    attrs.forEach((attr) => {
        newObject[attr] = object[attr].bind(object);
    });

    return newObject;
}

/**
 * Wraps react children elements with props.
 */
export function wrapChildrenWith(children, props) {
    return React.Children.map(children, (child) =>
        React.cloneElement(child, props)
    );
}

/**
 * Checks if the string includes the substring.
 *
 * @param  {String} str
 * @param  {String} substr
 * @return {Boolean}
 */
export function stringInclues(str, substr) {
    return str.indexOf(substr) !== -1;
}

const popHandler = (e) => {
    console.dir(e);
};
window.addEventListener('popstate', popHandler, { once: true });
(DropDownListComponent as any).prototype.onMouseClick = function (e) {
    //console.log('DropDownListComponent onMouseClick.')
    let target: Element = <Element>e.target;
    //let classList: DOMTokenList = target.classList;
    //let li: HTMLElement = <HTMLElement>closest(target, '.' + 'e-list-item');
    let li: HTMLElement = <HTMLElement>target.closest('.' + 'e-list-item');
    if (!this.isValidLI(li)) {
        return;
    }
    this.setSelection(li, e);
    if (Browser.isDevice && this.isFilterLayout()) {
        const delay = 100;
        this.closePopup(delay);
    } else {
        let delay = 100;
        this.closePopup(delay);
    }
};

export function flattenArray(inputArr) {
    const result = [];
    if (isNotNullAndUndefined(inputArr) && inputArr.length > 0) {
        inputArr.forEach((item) => {
            const { children } = item;
            result.push(item);
            if (children) result.push(...flattenArray(children));
        });
    }

    return result;
}

export function GetRAFLayoutByNumber(inVal?: number): RAFLayout {
    if (isNullOrUndefined(inVal)) return null;
    let retVal: RAFLayout = RAFLayout.OneColumnLayout;
    switch (inVal) {
        case 1:
            retVal = RAFLayout.OneColumnLayout;
            break;
        case 2:
            retVal = RAFLayout.TwoColumnLayout;
            break;
        case 3:
            retVal = RAFLayout.ThreeColumnLayout;
            break;
        case 4:
            retVal = RAFLayout.FourColumnLayout;
            break;
        default:
            break;
    }
    return retVal;
}

export function deepMerge(a, b) {
    return R.is(Object, a) && R.is(Object, b) ? R.mergeWith(deepMerge, a, b) : b;
}

export function copyPropertyvalues<T, K extends keyof T>(
    s: Pick<T, K>,
    d: T,
    ks: K[]
) {
    ks.forEach((k) => (d[k] = s[k]));
    return d;
}

export function isNumber(val) {
    if (typeof (val) === 'number') {
        return true;
    }
    return false;
}

export function isString(val) {
    if (typeof (val) === 'string') {
        return true;
    }
    return false;
}

export function isBoolean(val) {
    if (typeof (val) === 'boolean') {
        return true;
    }
    return false;
}

export function isDate(val) {
    if (val instanceof Date) {
        return true;
    }
    return false;
}

export function isArray(val) {
    if (Array.isArray(val)) {
        return true;
    }
    return false;
}

export function truncateBefore(str, pattern) {
    return str.slice(str.indexOf(pattern) + pattern.length);
};
export function truncateAfter(str, pattern) {
    return str.slice(0, str.indexOf(pattern));
}

// export type GridPageContext = {
//     viewMenuDataSource: RAFTreeViewMenu[], currentView: RAFTreeViewMenu, updateviewMenuDataSource?: (viewId?: string) => void, updateCurrentView?: (item: RAFTreeViewMenu) => void;
// }; //moved to rafmenuHelper

export { };


export const getDataTypeDisplayName = (dataType: string) => {
    let value;
    switch (dataType) {
        case (RAFDataType.Text):
            value = RAFDataTypeDisplayName.Text;
            break;
        case (RAFDataType.Multiline):
            value = RAFDataTypeDisplayName.Multiline;
            break;
        case (RAFDataType.Number):
            value = RAFDataTypeDisplayName.Number;
            break;
        case (RAFDataType.Boolean):
            value = RAFDataTypeDisplayName.Boolean;
            break;
        case (RAFDataType.Date):
            value = RAFDataTypeDisplayName.Date;
            break;
        case (RAFDataType.DateTime):
            value = RAFDataTypeDisplayName.DateTime;
            break;
        case (RAFDataType.Dropdown):
            value = RAFDataTypeDisplayName.Dropdown;
            break;
        case (RAFDataType.RadioButton):
            value = RAFDataTypeDisplayName.RadioButton;
            break;
        case (RAFDataType.MultiSelect):
            value = RAFDataTypeDisplayName.MultiSelect;
            break;
        case (RAFDataType.Paragraph):
            value = RAFDataTypeDisplayName.Paragraph;
            break;
        case (RAFDataType.Page):
            value = RAFDataTypeDisplayName.Page;
            break;
        case (RAFDataType.Panel):
            value = RAFDataTypeDisplayName.Panel;
            break;
        case (RAFDataType.Signature):
            value = RAFDataTypeDisplayName.Signature;
            break;
        case (RAFDataType.Attachment):
            value = RAFDataTypeDisplayName.Attachment;
            break;
        case (RAFDataType.Column):
            value = RAFDataTypeDisplayName.Column;
            break;
        default:
            value = "Input field";
            break;
    }
    return value;
};


export function moveItemInArray(arr, fromIndex, toIndex) {
    const element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
    return arr;
};

export function insertItemInArray(array, index, elementsArray) {
    const objArray = [...array];
    objArray.splice(index, 0, elementsArray);
    return objArray;
};

export function removeUndefinedKeys(obj) {
    for (var key in obj) {
        if (obj[key] === undefined) {
            delete obj[key];
        }
    }
    return obj;
}

export const isConnectedToInternet = async () => {
    return true;
    // try {
    //     //let url = //'https://jsonplaceholder.typicode.com/posts/';
    //     let url = `${Constants.baseAPIUrl}User/CheckInternetStatus`;
    //     const response = await fetch(
    //         url,
    //         {
    //             method: "POST",
    //         }
    //     );

    //     return response.status >= 200 && response.status < 300;
    // } catch (error) {
    //     return false;
    // }
};

export function getBase64(file) {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            resolve(reader.result);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
            resolve(null);
        };
    });
}

export function getMimeTypeByExtension(inVal: string): string {
    if (IsNullOrWhiteSpace(inVal)) {
        return null;
    }
    const extensionMimeTypes = {
        //   File Extension   MIME Type
        'abs': 'audio/x-mpeg',
        'ai': 'application/postscript',
        'aif': 'audio/x-aiff',
        'aifc': 'audio/x-aiff',
        'aiff': 'audio/x-aiff',
        'aim': 'application/x-aim',
        'art': 'image/x-jg',
        'asf': 'video/x-ms-asf',
        'asx': 'video/x-ms-asf',
        'au': 'audio/basic',
        'avi': 'video/x-msvideo',
        'avx': 'video/x-rad-screenplay',
        'bcpio': 'application/x-bcpio',
        'bin': 'application/octet-stream',
        'bmp': 'image/bmp',
        'body': 'text/html',
        'cdf': 'application/x-cdf',
        'cer': 'application/pkix-cert',
        'class': 'application/java',
        'cpio': 'application/x-cpio',
        'csh': 'application/x-csh',
        'css': 'text/css',
        'dib': 'image/bmp',
        'doc': 'application/msword',
        'dtd': 'application/xml-dtd',
        'dv': 'video/x-dv',
        'dvi': 'application/x-dvi',
        'eot': 'application/vnd.ms-fontobject',
        'eps': 'application/postscript',
        'etx': 'text/x-setext',
        'exe': 'application/octet-stream',
        'gif': 'image/gif',
        'gtar': 'application/x-gtar',
        'gz': 'application/x-gzip',
        'hdf': 'application/x-hdf',
        'hqx': 'application/mac-binhex40',
        'htc': 'text/x-component',
        'htm': 'text/html',
        'html': 'text/html',
        'ief': 'image/ief',
        'jad': 'text/vnd.sun.j2me.app-descriptor',
        'jar': 'application/java-archive',
        'java': 'text/x-java-source',
        'jnlp': 'application/x-java-jnlp-file',
        'jpe': 'image/jpeg',
        'jpeg': 'image/jpeg',
        'jpg': 'image/jpeg',
        'js': 'application/javascript',
        'jsf': 'text/plain',
        'json': 'application/json',
        'jspf': 'text/plain',
        'kar': 'audio/midi',
        'latex': 'application/x-latex',
        'm3u': 'audio/x-mpegurl',
        'mac': 'image/x-macpaint',
        'man': 'text/troff',
        'mathml': 'application/mathml+xml',
        'me': 'text/troff',
        'mid': 'audio/midi',
        'midi': 'audio/midi',
        'mif': 'application/x-mif',
        'mov': 'video/quicktime',
        'movie': 'video/x-sgi-movie',
        'mp1': 'audio/mpeg',
        'mp2': 'audio/mpeg',
        'mp3': 'audio/mpeg',
        'mp4': 'video/mp4',
        'mpa': 'audio/mpeg',
        'mpe': 'video/mpeg',
        'mpeg': 'video/mpeg',
        'mpega': 'audio/x-mpeg',
        'mpg': 'video/mpeg',
        'mpv2': 'video/mpeg2',
        'ms': 'application/x-wais-source',
        'nc': 'application/x-netcdf',
        'oda': 'application/oda',
        'odb': 'application/vnd.oasis.opendocument.database',
        'odc': 'application/vnd.oasis.opendocument.chart',
        'odf': 'application/vnd.oasis.opendocument.formula',
        'odg': 'application/vnd.oasis.opendocument.graphics',
        'odi': 'application/vnd.oasis.opendocument.image',
        'odm': 'application/vnd.oasis.opendocument.text-master',
        'odp': 'application/vnd.oasis.opendocument.presentation',
        'ods': 'application/vnd.oasis.opendocument.spreadsheet',
        'odt': 'application/vnd.oasis.opendocument.text',
        'otg': 'application/vnd.oasis.opendocument.graphics-template',
        'oth': 'application/vnd.oasis.opendocument.text-web',
        'otp': 'application/vnd.oasis.opendocument.presentation-template',
        'ots': 'application/vnd.oasis.opendocument.spreadsheet-template',
        'ott': 'application/vnd.oasis.opendocument.text-template',
        'ogx': 'application/ogg',
        'ogv': 'video/ogg',
        'oga': 'audio/ogg',
        'ogg': 'audio/ogg',
        'otf': 'application/x-font-opentype',
        'spx': 'audio/ogg',
        'flac': 'audio/flac',
        'anx': 'application/annodex',
        'axa': 'audio/annodex',
        'axv': 'video/annodex',
        'xspf': 'application/xspf+xml',
        'pbm': 'image/x-portable-bitmap',
        'pct': 'image/pict',
        'pdf': 'application/pdf',
        'pgm': 'image/x-portable-graymap',
        'pic': 'image/pict',
        'pict': 'image/pict',
        'pls': 'audio/x-scpls',
        'png': 'image/png',
        'pnm': 'image/x-portable-anymap',
        'pnt': 'image/x-macpaint',
        'ppm': 'image/x-portable-pixmap',
        'ppt': 'application/vnd.ms-powerpoint',
        'pps': 'application/vnd.ms-powerpoint',
        'ps': 'application/postscript',
        'psd': 'image/vnd.adobe.photoshop',
        'qt': 'video/quicktime',
        'qti': 'image/x-quicktime',
        'qtif': 'image/x-quicktime',
        'ras': 'image/x-cmu-raster',
        'rdf': 'application/rdf+xml',
        'rgb': 'image/x-rgb',
        'rm': 'application/vnd.rn-realmedia',
        'roff': 'text/troff',
        'rtf': 'application/rtf',
        'rtx': 'text/richtext',
        'sfnt': 'application/font-sfnt',
        'sh': 'application/x-sh',
        'shar': 'application/x-shar',
        'sit': 'application/x-stuffit',
        'snd': 'audio/basic',
        'src': 'application/x-wais-source',
        'sv4cpio': 'application/x-sv4cpio',
        'sv4crc': 'application/x-sv4crc',
        'svg': 'image/svg+xml',
        'svgz': 'image/svg+xml',
        'swf': 'application/x-shockwave-flash',
        't': 'text/troff',
        'tar': 'application/x-tar',
        'tcl': 'application/x-tcl',
        'tex': 'application/x-tex',
        'texi': 'application/x-texinfo',
        'texinfo': 'application/x-texinfo',
        'tif': 'image/tiff',
        'tiff': 'image/tiff',
        'tr': 'text/troff',
        'tsv': 'text/tab-separated-values',
        'ttf': 'application/x-font-ttf',
        'txt': 'text/plain',
        'ulw': 'audio/basic',
        'ustar': 'application/x-ustar',
        'vxml': 'application/voicexml+xml',
        'xbm': 'image/x-xbitmap',
        'xht': 'application/xhtml+xml',
        'xhtml': 'application/xhtml+xml',
        'xls': 'application/vnd.ms-excel',
        'xml': 'application/xml',
        'xpm': 'image/x-xpixmap',
        'xsl': 'application/xml',
        'xslt': 'application/xslt+xml',
        'xul': 'application/vnd.mozilla.xul+xml',
        'xwd': 'image/x-xwindowdump',
        'vsd': 'application/vnd.visio',
        'wav': 'audio/x-wav',
        'wbmp': 'image/vnd.wap.wbmp',
        'wml': 'text/vnd.wap.wml',
        'wmlc': 'application/vnd.wap.wmlc',
        'wmls': 'text/vnd.wap.wmlsc',
        'wmlscriptc': 'application/vnd.wap.wmlscriptc',
        'wmv': 'video/x-ms-wmv',
        'woff': 'application/font-woff',
        'woff2': 'application/font-woff2',
        'wrl': 'model/vrml',
        'wspolicy': 'application/wspolicy+xml',
        'z': 'application/x-compress',
        'zip': 'application/zip'
    };

    return extensionMimeTypes[inVal];
}

export const CalculateDate = (days: string): Date => {
    let dateVal: Date = null;
    switch (days) {
        case 'NoDate':
            //dateVal = null;
            dateVal = Constants.MaxDate;
            break;
        case '0':
            dateVal = setZeroHours(moment().toDate());
            break;
        case '1':
            dateVal = setZeroHours(moment().add(1, 'days').toDate());
            break;
        case '3':
            dateVal = setZeroHours(moment().add(3, 'days').toDate());
            break;
        case '7':
            dateVal = setZeroHours(moment().add(7, 'days').toDate());
            break;
        case '15':
            dateVal = setZeroHours(moment().add(15, 'days').toDate());
            break;
        case '30':
            dateVal = setZeroHours(moment().add(30, 'days').toDate());
            break;
        case '60':
            dateVal = setZeroHours(moment().add(60, 'days').toDate());
            break;
        case 'ASAP':
            dateVal = Constants.MinDate;
            break;
        default:
            break;
    }
    return dateVal;
};

// export enum dateValueConstant{
//     day_0 = '0',
//     day_1 = '1',
//     day_2 = '2',
//     day_3 = '3',
//     day_7 = '7',
//     day_15 = '15',
//     day_30 = '30',
//     day_60 = '60',
//     day_90 = '90',
//     ASAP = 'ASAP',
//     NoDate = 'NoDate',
//     Unplanned = 'Unplanned',
// }

export const encodeBase64 = (data) => {
    return Buffer.from(data).toString('base64');
};
export const decodeBase64 = (data) => {
    return Buffer.from(data, 'base64').toString('ascii');
};

export const parseJwt = (token) => {
    try {
        console.log({ token });
        console.log(token.split('.'));
        console.log(token.split('.')[1]);
        return JSON.parse(decodeBase64(token.split('.')[1]));
        //return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
    } catch (e) {
        return null;
    }
};


// a function to retry loading a chunk to avoid chunk load error for out of date code
export const lazyRetry = function (componentImport, name) {
    return new Promise<{ default: ComponentType<any>; }>((resolve, reject) => {
        // check if the window has already been refreshed
        const hasRefreshed = JSON.parse(
            window.sessionStorage.getItem(`retry-${name}-refreshed`) || 'false'
        );
        // try to import the component
        componentImport().then((component) => {
            window.sessionStorage.setItem(`retry-${name}-refreshed`, 'false'); // success so reset the refresh
            resolve(component);
        }).catch((error) => {
            if (!hasRefreshed) { // not been refreshed yet
                window.sessionStorage.setItem(`retry-${name}-refreshed`, 'true'); // we are now going to refresh
                return window.location.reload(); // refresh the page
            }
            reject(error); // Default error behaviour as already tried refresh
        });
    });
};

export const getBlobDocumentFile = (uid: string, displayName: string) => {
    return new Promise<File>((resolve, reject) => {
        let url = `${Constants.baseAPIUrl}ContentLibrary/Download`;
        const objData = { EntityId: uid };
        axios({
            url: url,
            method: "POST",
            responseType: "blob",
            headers: {
                [RAFHeaderNames.Authorization]: `Bearer ${msalInstance.accessToken}`,
                [RAFHeaderNames.BusinessUnitUID]: msalInstance.currentBusinessUnitId,
                [RAFHeaderNames.Domain]: getPureSubDomainOrHint(),
            },
            data: objData,
        })
            .then((response) => {
                if (
                    response.status === 200 &&
                    isNotNullAndUndefined(response) &&
                    isNotNullAndUndefined(response.data)
                ) {
                    const arrayBuffer = response.data;
                    const blob = new Blob([arrayBuffer], {
                        type: response.headers["content-type"],
                    });
                    let newFile = new File([blob], displayName, { type: blob.type });
                    resolve(newFile);
                } else {
                    resolve(null);
                }
            })
            .catch((error) => {
                resolve(null);
            });
    });
};

export const downloadFile = (uid: string, displayName: string) => {
    return new Promise<boolean>((resolve, reject) => {
        let url = `${Constants.baseAPIUrl}ContentLibrary/Download`;
        const objData = { EntityId: uid };
        axios({
            url: url,
            method: "POST",
            responseType: "blob",
            headers: {
                [RAFHeaderNames.Authorization]: `Bearer ${msalInstance.accessToken}`,
                [RAFHeaderNames.BusinessUnitUID]: msalInstance.currentBusinessUnitId,
                [RAFHeaderNames.Domain]: getPureSubDomainOrHint(),
            },
            data: objData,
        })
            .then((response) => {
                if (
                    response.status === 200 &&
                    isNotNullAndUndefined(response) &&
                    isNotNullAndUndefined(response.data)
                ) {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;

                    let extension;

                    if (isNotNullAndUndefined(displayName)) {
                        let x = displayName.split(".").pop();
                        extension = isNotNullAndUndefined(x) ? x : null;
                    }

                    // if (isNotNullAndUndefined(fileName)) {
                    //   let y = fileName.split(".").pop();
                    //   if (isNotNullAndUndefined(y) && extension !== y) {
                    //     extension = y;
                    //   } else {
                    //     extension = null;
                    //   }
                    // }

                    let downloadDocName = "Notset.txt";

                    if (isNotNullAndUndefined(extension)) {
                        if (isNotNullAndUndefined(displayName)) {
                            downloadDocName = displayName + "." + extension;
                        }
                    } else if (isNotNullAndUndefined(displayName)) {
                        downloadDocName = displayName;
                    }

                    link.setAttribute("download", downloadDocName);
                    document.body.appendChild(link);
                    link.click();

                    resolve(true);
                } else {
                    resolve(null);
                }
            })
            .catch((error) => {
                resolve(null);
            });
    });
};

export function base64ToFile(data, filename) {
    if (IsNotNullOrWhiteSpace(data)) {
        const splitData = data.split(',');
        const fileInfo = splitData[0];
        const base64Data = splitData[1];

        const type = fileInfo.split(';')[0].split(':')[1];

        const byteCharacters = atob(base64Data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: type });
        const file = new File([blob], filename, { type: type, lastModified: Date.now() });

        return file;
    } else {
        return null;
    }
}

export const fileUpload = (
    file?: File,
    itemUID?: string,
    // entityId?: string,
    // objectName?: string,
    moduleName?: string
) => {
    return new Promise<{ entityId: string; objectName: string; }>(
        async (resolve) => {
            if (isNotNullAndUndefined(file)) {
                const formData = new FormData();
                if (isNotNullAndUndefined(itemUID)) {
                    formData.append("UID", itemUID);
                }
                const compressedImg = await getCompressedImage(file);
                formData.append(
                    "file",
                    isNotNullAndUndefined(compressedImg) ? compressedImg : file
                );
                formData.append("file", file);
                // formData.append("RelatedToUID", entityId);
                // formData.append("RelatedTo", objectName ?? "");
                formData.append("RelatedToType", moduleName);
                formData.append("Entity", moduleName);
                formData.append("CurrentStatus", ContentLibraryCurrentStatus.Published);

                return repositoryActions
                    .postDataAndGetResponse(
                        "ContentLibrary/Upload",
                        formData,
                        null,
                        ContentType.applicationFormUrlEncoded
                    )
                    .then((response) => {
                        if (
                            isNotNullAndUndefined(response) &&
                            isNotNullAndUndefined(response.data) &&
                            isNotNullAndUndefined(response.data.documentUIDs) &&
                            isNotNullAndUndefined(response.data.documentUIDs[0]) &&
                            isNotNullAndUndefined(response.data.documentUIDs[0].EntityId)
                        ) {
                            resolve({
                                entityId: response.data.documentUIDs[0].EntityId,
                                objectName: response.data.documentUIDs[0].ObjectName,
                            });
                        } else {
                            resolve(null);
                        }
                    })
                    .catch((error) => resolve(null));
            } else {
                resolve(null);
            }
        }
    );
};


export const getCompressedImage = (fileData: File) => {
    return new Promise<File | Blob>((resolve) => {
        if (isNotNullAndUndefined(fileData) && fileData.type.startsWith("image/")) {
            new Compressor(fileData, {
                quality: 0.7, // Quality between 0 and 1
                maxWidth: 1920, // Maximum width
                maxHeight: 1920, // Maximum height
                checkOrientation: true, // Correct orientation
                //convertSize: 5000000, // Convert images smaller than 5MB to base64 string without compression
                // The compression process is asynchronous,
                // which means you have to access the `result` in the `success` hook function.
                success(result) {
                    if (result instanceof Blob) {
                        const fileName = fileData.name;
                        const file = new File([result], fileName, { type: result.type });
                        resolve(file);
                    } else {
                        resolve(result);
                    }
                },
                error(err) {
                    resolve(null);
                    console.log(err.message);
                },
            });
        } else {
            resolve(null);
        }
    });
};

export const getResizedImage = (fileData: File) => {
    return new Promise<File | Blob>((resolve) => {
        if (isNotNullAndUndefined(fileData) && fileData.type.startsWith("image/")) {
            new Compressor(fileData, {
                quality: 0.6, // Quality between 0 and 1
                //retainExif: false, // Don't retain Exif data
                // width: 120,
                // height: 120,
                maxHeight: 150,
                maxWidth: 150,
                resize: "contain",

                // The compression process is asynchronous,
                // which means you have to access the `result` in the `success` hook function.
                success(result) {
                    if (result instanceof Blob) {
                        const fileName = fileData.name;
                        const file = new File([result], fileName, { type: result.type });
                        resolve(file);
                    } else {
                        resolve(result);
                    }
                },
                error(err) {
                    resolve(null);
                    console.log(err.message);
                },
            });
        } else {
            resolve(null);
        }
    });
};