import React from "react";
import { isNotNullAndUndefined, IsNullOrWhiteSpace } from "../helpers/utils";
import "./NavigationStyle.scss";

const RAFIconImage = props => {
    let value = props.value;
    let iconSize = props.iconSize;
    let fontSize = props.fontSize;
    let fontWeight = props.fontWeight;
    let backgroundColor = props.backgroundColor;
    let color = props.color;
    let border = props.border;
    let userIcon = props.userIcon;
    let iconCssClass = props.iconCssClass;
    let moduleavatar = props.moduleavatar;
    let tooltipText = props.tooltipText;
    let initialcharAt: number = props.initialcharAt;
    let avatarInitialClass = props.avatarInitialClass;
    let enableTwoLetters: boolean = props.enableTwoLetters;
    const showFullText = props.showFullText ?? false;
    let iconCssClassHTML: React.ReactFragment | React.ReactNode = props.iconCssClassHTML;

    let initial;
    let initialValue;

    if (showFullText && !IsNullOrWhiteSpace(value)) {
        initial = value && value.toString().toUpperCase();
    } else if (!IsNullOrWhiteSpace(value)) {
        let value1 = value && value.toString();
        if (isNotNullAndUndefined(initialcharAt) && isNotNullAndUndefined(value1.charAt(initialcharAt))) {
            initial = value1.charAt(initialcharAt).toUpperCase();
        }
        else {
            if (enableTwoLetters && value1.length > 1) {
                value = value.split(' ').filter((item) => item !== '');

                if (isNotNullAndUndefined(value[1])) {
                    initialValue = value[0].charAt(0).toUpperCase() + value[1].charAt(0).toUpperCase();
                }
                else {
                    initialValue = value[0].charAt(0).toUpperCase() + value[0].charAt(1).toUpperCase();
                }
            }
            initial = value1.charAt(0).toUpperCase();
        }
    }
    let avatar;
    if (userIcon === true) {
        avatar =
            <div id="iconImage" style={{ cursor: 'pointer' }}>
                <span
                    className={isNotNullAndUndefined(moduleavatar) ? moduleavatar + " e-avatar e-avatar-circle" : "e-avatar e-avatar-small e-avatar-circle"}
                    style={{
                        marginRight: "0.625rem"
                    }}
                >
                    <span className="fas fa-user"></span>
                </span>
            </div>;
    }
    else if (isNotNullAndUndefined(iconCssClass) || isNotNullAndUndefined(iconCssClassHTML)) {
        avatar =
            <div id="iconImage" style={{ cursor: 'pointer' }}>
                <span
                    className={isNotNullAndUndefined(iconCssClass) ? isNotNullAndUndefined(moduleavatar) ? moduleavatar + " avatar-text" :
                        isNotNullAndUndefined(avatarInitialClass) ?
                            `avatar-text ${avatarInitialClass} ${avatarInitialClass}-${initial}` :
                            "avatar-text avatar-initial-palettes-" + initial : ""}
                    style={{
                        width: (iconSize !== null) ? iconSize + "px" : "",
                        height: (iconSize !== null) ? iconSize + "px" : "",
                        minWidth: (iconSize !== null) ? iconSize + "px" : "",
                        maxWidth: (iconSize !== null) ? iconSize + "px" : "",
                        fontSize: (fontSize !== null) ? fontSize + "px" : "",
                        backgroundColor: (backgroundColor !== null) ? backgroundColor : "",
                        color: (color !== null) ? color : "",
                        border: (border !== null) ? border : "",

                        fontWeight: (fontWeight !== null) ? fontWeight : null
                    }}>
                    {isNotNullAndUndefined(iconCssClassHTML) ?
                        iconCssClassHTML
                        :
                        <span className={iconCssClass}
                            style={{
                                fontSize: (fontSize !== null) ? fontSize + "px" : "",
                            }}></span>
                    }
                </span>
            </div>;
    }
    else {
        // avatar = <TooltipComponent content={isNotNullAndUndefined(tooltipText) ? tooltipText : (showFullText ? initial : value)} position='BottomCenter'>
        //     <div id="iconImage" style={{ cursor: 'pointer' }}>
        //         <span
        //             className={value !== null && value !== undefined ? moduleavatar !== null && moduleavatar !== undefined ? moduleavatar + " avatar-text" : "avatar-text avatar-initial-palettes-" + initial : ""}
        //             style={{
        //                 width: (iconSize !== null) ? iconSize + "px" : "",
        //                 height: (iconSize !== null) ? iconSize + "px" : "",
        //                 minWidth: (iconSize !== null) ? iconSize + "px" : "",
        //                 maxWidth: (iconSize !== null) ? iconSize + "px" : "",
        //                 fontSize: (fontSize !== null) ? fontSize + "px" : "",
        //                 backgroundColor: (backgroundColor !== null) ? backgroundColor : "",
        //                 color: (color !== null) ? color : "rgba(0, 0, 0, 0.8)",
        //                 fontWeight: (fontWeight !== null) ? fontWeight : null
        //             }}>
        //             {isNotNullAndUndefined(initial) &&
        //                 <span>{initial}</span>
        //             }
        //         </span>
        //     </div>
        // </TooltipComponent>
        avatar = <div id="iconImage" style={{ cursor: 'pointer' }}>
            <span
                className={value !== null && value !== undefined ?
                    moduleavatar !== null && moduleavatar !== undefined ?
                        moduleavatar + " avatar-text"
                        :
                        isNotNullAndUndefined(avatarInitialClass) ?
                            `avatar-text ${avatarInitialClass}-${initial}` :
                            "avatar-text avatar-initial-palettes-" + initial
                    : ""}
                style={{
                    width: (iconSize !== null) ? iconSize + "px" : "",
                    height: (iconSize !== null) ? iconSize + "px" : "",
                    minWidth: (iconSize !== null) ? iconSize + "px" : "",
                    maxWidth: (iconSize !== null) ? iconSize + "px" : "",
                    fontSize: (fontSize !== null) ? fontSize + "px" : "",
                    backgroundColor: (backgroundColor !== null) ? backgroundColor : "",
                    color: (color !== null) ? color : "rgba(0, 0, 0, 0.8)",
                    border: (border !== null) ? border : "",
                    fontWeight: (fontWeight !== null) ? fontWeight : null
                }}>
                {isNotNullAndUndefined(initial) &&
                    <span>{enableTwoLetters ? initialValue : initial}</span>
                }
            </span>
        </div>;
    }

    return (
        <div>
            {avatar}
        </div>
    );
};
export default RAFIconImage;
