import React, { createContext } from "react";

export const RAFFullScreenContext = createContext<{
  mode?: string;
  updateViewMode?: (mode?: "default" | "fullscreen") => void;
}>({ mode: null, updateViewMode: null });

export default function RAFFullScreenContextProvider({ children }) {
  const [viewMode, setViewMode] = React.useState("default");

  const updateViewMode = (viewMode: "default" | "fullscreen") => {
    setViewMode(viewMode);
  };

  return (
    <div>
      <RAFFullScreenContext.Provider
        value={{
          mode: viewMode,
          updateViewMode: updateViewMode,
        }}
      >
        <div>{children}</div>
      </RAFFullScreenContext.Provider>
    </div>
  );
}
