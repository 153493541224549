import { AccountInfo } from "@azure/msal-browser";
import { Popup } from "@syncfusion/ej2-popups";
import { GridModel } from "@syncfusion/ej2-react-grids";
import {
  TabActionSettingsModel,
  TabAnimationSettingsModel,
} from "@syncfusion/ej2-react-navigations";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import { RuleModel } from "@syncfusion/ej2-react-querybuilder";
import moment from "moment";
import * as R from "ramda";
import React from "react";
import { msalInstance } from "../..";
import { loginRequest } from "../../RAFAzure/AuthModule";
import {
  IsNotNullOrWhiteSpace,
  getJSONFromSessionStorage,
  getQueryString,
  isArray,
  isNotEmptyArray,
  isNullOrUndefined,
  propertyOf,
} from "../../RAFComponents/helpers/utils";
import { RMStorageKey } from "../../RAFMaster/helpers/RMConstants";
import { getDefaultViewsByModuleName } from "../../RAFModules/Common/List/IndexHelper";
import { UserDefinition } from "../../SkytrustModules/HR/Employee/Models/LogInAsRequestRow";
import acloading from "../../images/LoaderIcon.svg";
import {
  RAFCustomFilter,
  RAFCustomOperator,
} from "../RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import { AttributeRelatedList } from "../models/Common/AttributeRelatedList";
import { AttributeRow } from "../models/Common/AttributeRow";
import {
  ClientTerminologyJM,
  getClientTerminologiesFromStorage,
  setClientTerminologiesInStorage,
} from "../models/Common/ClientTerminologyJM";
import { FormLibraryRow } from "../models/Common/FormLibraryRow";
import {
  EntityListRequest,
  ListServiceRequest,
  ViewListRequest,
} from "../models/Common/ListRequest";
import {
  QueryAttributeJM,
  RAFOperator,
  ValueJson,
} from "../models/Common/QueryAttributeJM";
import { MenuRow, RAFMenuModel } from "../models/Common/RAFMenuModel";
import { RAFViewRow } from "../models/Common/RAFViewRow";
import { ReportJM } from "../models/Common/ReportJM";
import { LookUpRow, RelatedTo } from "../models/CompositeTypes/LookUpRow";
import * as repositoryActions from "./../../RAFComponents/store/actions/repositoryActions";
import {
  Constants,
  ContentType,
  RAFEntityName,
  SFColumnType,
  SecureAppAdministrator,
  SecureSuperAdministrator,
  StorageKey,
  getURLPrefixByModuleName,
} from "./Constants";
import { MenuCategory, removeFromFocusList } from "./RAFMenuHelper";
import {
  getRAFOperatorBySFOperator,
  getSFOperatorByRAFOperator,
} from "./SFGridUtils";
import { RAFSort } from "./types";
import { Guid, IsNullOrWhiteSpace, isNotNullAndUndefined } from "./utils";

export enum RAFActionMessage {
  RecordSaved = "Record saved successfully",
  RecordNotSaved = "Apologies, we're unable to save the record at the moment. Please try again later.!",
  RecordDeleted = "Record deleted successfully",
  RecordNotDeleted = "Apologies, we're unable to delete the record at the moment. Please try again later.!",
}

export const getClientTerminologies = (moduleName) => {
  //let url = moduleName + '/ClientTerminologies'
  let url = "ClientTerminology/List";
  return new Promise<ClientTerminologyJM[]>((resolve) => {
    const empClientTerms = getClientTerminologiesFromStorage(
      StorageKey.clterms_modulename + moduleName
    );

    if (isNotEmptyArray(empClientTerms)) {
      const uniqClientTerms: ClientTerminologyJM[] = R.uniq(empClientTerms);
      resolve(uniqClientTerms);
    } else {
      return repositoryActions
        .postDataAndGetResponse(
          url,
          { EntityName: moduleName },
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          const empClientTerms: ClientTerminologyJM[] = response.data.Entities;
          setClientTerminologiesInStorage(
            StorageKey.clterms_modulename + moduleName,
            empClientTerms
          );
          const uniqClientTerms: ClientTerminologyJM[] = R.uniq(empClientTerms);
          resolve(uniqClientTerms);
        })
        .catch((error) => error);
    }
  });
};

export const getReportListByModuleName = (moduleName: string) => {
  let url = `Report/List`;
  let relatedFilter: RAFCustomFilter = {};
  relatedFilter.Condition = "and";
  relatedFilter.Rules = [];

  let filter1: RAFCustomFilter = {};
  let filterVal1: string[] = [];
  filterVal1.push(moduleName);
  filter1.Operator = RAFCustomOperator.Equal;
  filter1.Value = filterVal1;
  filter1.Field = propertyOf<ReportJM>("Entity");
  relatedFilter.Rules.push(filter1);

  let listServiceRequest = new ListServiceRequest();
  listServiceRequest.CustomFilter = relatedFilter;
  listServiceRequest.Skip = 0;
  listServiceRequest.Take = 0;

  //listServiceRequest.ViewName = "all_report";

  return new Promise<ReportJM[]>((resolve) => {
    if (isNotNullAndUndefined(moduleName)) {
      const allReportViewStorage = getSessionStorage(
        StorageKey.allReportView_modulename + moduleName,
        true
      );
      if (
        isNotNullAndUndefined(allReportViewStorage) &&
        !IsNullOrWhiteSpace(allReportViewStorage) &&
        JSON.parse(allReportViewStorage).length > 0
      ) {
        const reportList: ReportJM[] = JSON.parse(allReportViewStorage);
        resolve(reportList);
      } else {
        return repositoryActions
          .postDataAndGetResponse(
            url,
            listServiceRequest,
            null,
            ContentType.applicationJson,
            false
          )
          .then((response) => {
            const reportList: ReportJM[] =
              isNotNullAndUndefined(response) &&
              isNotNullAndUndefined(response.data) &&
              isNotNullAndUndefined(response.data.Entities)
                ? response.data.Entities
                : null;
            if (isNotNullAndUndefined(reportList) && reportList.length > 0) {
              setSessionStorage(
                StorageKey.allReportView_modulename + moduleName,
                true,
                JSON.stringify(reportList)
              );
              resolve(reportList);
            } else {
              removeSessionStorage(
                StorageKey.allReportView_modulename + moduleName
              );
              resolve(null);
            }
          })
          .catch((error) => error);
      }
    } else {
      resolve(null);
    }
  });
};

export function getDependenciesByViewID(uid: string) {
  return new Promise<RelatedTo[]>((resolve) => {
    if (isNotNullAndUndefined(uid)) {
      return repositoryActions
        .postDataAndGetResponse(
          "View/Dependencies",
          { EntityId: uid },
          null,
          ContentType.applicationJson,
          false
        )
        .then((response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data)
          ) {
            resolve(response.data);
          } else {
            resolve(null);
          }
        })
        .catch((error) => error);
    } else {
      resolve(null);
    }
  });
}

///showInlist true --- isPublic && isPrivate && createdBy === loggedinUser --- viewType defult or group
export const getUserViewsByModuleName = (
  moduleName: string,
  //defaultView?: RAFViewRow,
  addEmptyView?: boolean,
  filterListViews?: boolean //default true
) => {
  return new Promise<RAFViewRow[]>(async (resolve) => {
    if (isNotNullAndUndefined(moduleName)) {
      if (hasDefaultModuleView(moduleName)) {
        const userViewDropdownItem = await filterUserViewDropdownItem(
          moduleName,
          addEmptyView,
          [],
          filterListViews
        );
        resolve(userViewDropdownItem);
      } else {
        const viewsStorage = getSessionStorage(
          StorageKey.allViews_modulename + moduleName,
          true
        );
        const allViews: RAFViewRow[] = !IsNullOrWhiteSpace(viewsStorage)
          ? JSON.parse(viewsStorage)
          : null;
        if (isNotEmptyArray(allViews)) {
          let userViewDropdownItem = await filterUserViewDropdownItem(
            moduleName,
            addEmptyView,
            allViews,
            filterListViews
          );
          resolve(userViewDropdownItem);
        } else {
          let url = `${Constants.baseAPIUrl}View/ListAll`;
          let viewListRequest = new ViewListRequest();
          viewListRequest.EntityName = moduleName;
          return repositoryActions
            .postDataAndGetResponse(
              url,
              viewListRequest,
              null,
              ContentType.applicationJson
            )
            .then(async (response) => {
              if (
                isNotNullAndUndefined(response) &&
                isNotNullAndUndefined(response.data) &&
                isNotEmptyArray(response.data.Entities)
              ) {
                const allViews: RAFViewRow[] = response.data.Entities;
                setSessionStorage(
                  StorageKey.allViews_modulename + moduleName,
                  true,
                  JSON.stringify(allViews)
                );
                const userViewDropdownItem = filterUserViewDropdownItem(
                  moduleName,
                  addEmptyView,
                  allViews,
                  filterListViews
                );
                resolve(userViewDropdownItem);
              } else {
                let defaultViews: RAFViewRow[] =
                  addEmptyView === true
                    ? getDefaultViewsByModuleName(moduleName)
                    : [];
                resolve(defaultViews);
              }
            });
        }
      }
    } else {
      resolve(null);
    }
  });
};

export function filterUserViewDropdownItem(
  moduleName: string,
  addEmptyView: boolean,
  allViews: RAFViewRow[],
  filterListViews: boolean //default true
) {
  let defaultViews: RAFViewRow[] =
    addEmptyView === true ? getDefaultViewsByModuleName(moduleName) : [];
  if (isNotEmptyArray(allViews)) {
    let dropDownItems: RAFViewRow[] = [];
    const loggedinUserId = msalInstance.currentUserId;
    allViews.forEach((item) => {
      if (
        isNotNullAndUndefined(item.SettingsJson) &&
        (item.SettingsJson.IsPrivate === false ||
          isNullOrUndefined(item.SettingsJson.IsPrivate) ||
          (item.SettingsJson.IsPrivate === true &&
            isNotNullAndUndefined(item.CreatedByUID) &&
            item.CreatedByUID === loggedinUserId))
        //  &&
        // item.ViewType === RAFViewType.Default
      ) {
        dropDownItems.push(item);
      }
    });
    if (isNotEmptyArray(dropDownItems)) {
      if (isNotNullAndUndefined(filterListViews) && filterListViews === false) {
        return dropDownItems;
      } else {
        let listViewItems = dropDownItems.filter(
          (x) => x.SettingsJson.ShowInList === true
        );
        if (isNotEmptyArray(listViewItems)) {
          const sortOrder = listViewItems.sort((a, b) => {
            if (a.IsSystem && !b.IsSystem) {
              return -1;
            }
            if (!a.IsSystem && b.IsSystem) {
              return 1;
            }
            return 0;
          });
          return sortOrder;
        } else {
          return defaultViews;
        }
      }
    } else {
      return defaultViews;
    }
  } else {
    return defaultViews;
  }
}

export const getAllViewsByModuleName = (moduleName: string) => {
  return new Promise<RAFViewRow[]>((resolve) => {
    const allViewsStorage = getSessionStorage(
      StorageKey.allViews_modulename + moduleName,
      true
    );
    if (
      isNotNullAndUndefined(allViewsStorage) &&
      !IsNullOrWhiteSpace(allViewsStorage)
    ) {
      const allViews: RAFViewRow[] = JSON.parse(allViewsStorage);
      resolve(allViews);
    } else {
      let url = `${Constants.baseAPIUrl}View/ListAll`;
      let viewListRequest = new ViewListRequest();
      viewListRequest.EntityName = moduleName;
      return repositoryActions
        .postDataAndGetResponse(
          url,
          viewListRequest,
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          const allViews: RAFViewRow[] = response.data.Entities;
          setSessionStorage(
            StorageKey.allViews_modulename + moduleName,
            true,
            JSON.stringify(allViews)
          );
          resolve(allViews);
        })
        .catch((error) => error);
    }
  });
};

export const getViewById = (viewId?: string, moduleName?: string) => {
  let rafViewRow: RAFViewRow = new RAFViewRow();
  return new Promise<RAFViewRow>((resolve) => {
    if (IsNullOrWhiteSpace(viewId)) {
      resolve(null);
    }
    const allViewsStorage = getSessionStorage(
      StorageKey.allViews_modulename + moduleName,
      true
    );
    if (
      isNotNullAndUndefined(allViewsStorage) &&
      !IsNullOrWhiteSpace(allViewsStorage)
    ) {
      const allViews: RAFViewRow[] = JSON.parse(allViewsStorage);
      if (isNotNullAndUndefined(allViews)) {
        rafViewRow = allViews.find((x) => x.UID === viewId);
      }
      if (isNotNullAndUndefined(rafViewRow)) {
        resolve(rafViewRow);
      } else {
        resolve(null);
      }
    } else {
      return repositoryActions
        .postDataAndGetResponse(
          `${Constants.baseAPIUrl}View/Retrieve`,
          { EntityId: viewId },
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data) &&
            isNotNullAndUndefined(response.data.Entity)
          ) {
            rafViewRow = response.data.Entity;
            resolve(rafViewRow);
          } else {
            resolve(null);
          }
        })
        .catch((error) => error);
    }
  });
};

export const getViewByName = (viewName?: string, moduleName?: string) => {
  let rafViewRow: RAFViewRow = new RAFViewRow();
  let listRequest: ViewListRequest = new ViewListRequest();
  return new Promise<RAFViewRow>((resolve) => {
    const allViewsStorage = getSessionStorage(
      StorageKey.allViews_modulename + moduleName,
      true
    );
    if (
      isNotNullAndUndefined(allViewsStorage) &&
      !IsNullOrWhiteSpace(allViewsStorage)
    ) {
      const allViews: RAFViewRow[] = JSON.parse(allViewsStorage);
      if (isNotNullAndUndefined(allViews)) {
        rafViewRow = allViews.find((x) => x.ViewName === viewName);
      }
      if (isNotNullAndUndefined(rafViewRow)) {
        resolve(rafViewRow);
      }
    } else {
      listRequest.EntityName = moduleName;
      return repositoryActions
        .postDataAndGetResponse(
          `${Constants.baseAPIUrl}View/ListAll`,
          listRequest,
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          let rafAllViews: RAFViewRow[] = response.data.Entities;
          setSessionStorage(
            StorageKey.allViews_modulename + moduleName,
            true,
            JSON.stringify(rafAllViews)
          );
          if (isNotNullAndUndefined(rafAllViews)) {
            rafViewRow = rafAllViews.find((x) => x.ViewName === viewName);
          }
          resolve(rafViewRow);
        })
        .catch((error) => error);
    }
  });
};

export const RetrieveRecord = (
  recordId: string,
  moduleName: string,
  entityUID?: string,
  defaultValue?: string,
  customurl?: string
) => {
  return new Promise<any>((resolve) => {
    if (
      isNotNullAndUndefined(recordId) &&
      isNotNullAndUndefined(moduleName) &&
      !IsNullOrWhiteSpace(recordId)
    ) {
      let url =
        customurl ??
        `${getURLPrefixByModuleName(
          moduleName,
          defaultValue,
          entityUID
        )}/Retrieve`;
      return repositoryActions
        .postDataAndGetResponse(
          url,
          { EntityId: recordId, EntityName: moduleName, EntityUID: entityUID },
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (isNotNullAndUndefined(response)) {
            if (response.status === 204) {
              resolve(null);
            } else if (
              isNotNullAndUndefined(response.data) &&
              isNotNullAndUndefined(response.data.Entity)
            ) {
              resolve(response.data.Entity);
            } else if (
              response.data &&
              response.data.ResultTable &&
              response.data.ResultTable.length > 0
            ) {
              resolve(response.data.ResultTable[0]);
            } else {
              resolve(null);
            }
          } else {
            resolve(null);
          }
        })
        .catch((error) => error);
    } else {
      resolve(null);
    }
  });
};

export const DeleteRecord = (
  recordIds: any,
  moduleName: string,
  entityUID?: string,
  defaultValue?: string,
  defaultURL?: string
) => {
  return new Promise<Boolean>((resolve) => {
    if (isNotNullAndUndefined(recordIds) && isNotNullAndUndefined(moduleName)) {
      const url = !IsNullOrWhiteSpace(defaultURL)
        ? defaultURL
        : `${getURLPrefixByModuleName(
            moduleName,
            defaultValue,
            entityUID
          )}/Delete`;
      return repositoryActions
        .postDataAndGetResponse(
          url,
          { EntityId: recordIds, EntityName: moduleName, EntityUID: entityUID },
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (isNotNullAndUndefined(response) && response.data === true) {
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch((error) => error);
    } else {
      resolve(null);
    }
  });
};

// export const DeleteDataListRecord = (
//   recordIds?: any,
//   moduleName?: string,
//   entityUID?: string
// ) => {
//   return new Promise<Boolean>((resolve) => {
//     if (isNotNullAndUndefined(recordIds) && isNotNullAndUndefined(moduleName)) {
//       return repositoryActions
//         .postDataAndGetResponse(
//           `DataList/Delete`,
//           { EntityId: recordIds, EntityUID: entityUID },
//           null,
//           ContentType.applicationJson
//         )
//         .then((response) => {
//           if (isNotNullAndUndefined(response) && response.data === true) {
//             resolve(true);
//           } else {
//             resolve(false);
//           }
//         })
//         .catch((error) => error);
//     } else {
//       resolve(null);
//     }
//   });
// };

export const DeleteRecordRemoveFocus = (recordIds, moduleName) => {
  return new Promise((resolve) => {
    if (isNotNullAndUndefined(recordIds) && isNotNullAndUndefined(moduleName)) {
      return repositoryActions
        .postDataAndGetResponse(
          `${moduleName}/Delete`,
          { EntityId: recordIds },
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data)
          ) {
            removeFromFocusList(recordIds)
              .then(() => {
                resolve(response.data);
              })
              .catch((error) => error);
          } else {
            resolve(response.data);
          }
        })
        .catch((error) => error);
    } else {
      resolve(false);
    }
  });
};

export const getEntityIdByName = (entityName?: string) => {
  let url = `Entity/Retrieve`;
  return new Promise<string>((resolve) => {
    return repositoryActions
      .postDataAndGetResponse(
        url,
        { ObjectName: entityName },
        null,
        ContentType.applicationJson,
        false
      )
      .then((response) => {
        const entityRow = response.data.Entity;
        resolve(entityRow.UID);
      })
      .catch((error) => error);
  });
};

export const getFields = (moduleName?: string, viewId?: string) => {
  let url = `${Constants.baseAPIUrl}Attribute/ListFields`;
  let entityListRequest = new EntityListRequest();
  entityListRequest.EntityName = moduleName;
  return new Promise<QueryAttributeJM[]>((resolve) => {
    const viewAttributesStorage = getSessionStorage(
      StorageKey.viewAttribs_modulename +
        (isNotNullAndUndefined(viewId) ? viewId : moduleName),
      true
    );
    //console.log('viewAttribs_' + (typeName));
    if (
      isNotNullAndUndefined(viewAttributesStorage) &&
      !IsNullOrWhiteSpace(viewAttributesStorage)
    ) {
      const viewAttributes: QueryAttributeJM[] = JSON.parse(
        viewAttributesStorage
      );
      if (isNotNullAndUndefined(viewAttributes)) {
        const uniqViewAttributes: QueryAttributeJM[] = R.uniq(viewAttributes);
        resolve(uniqViewAttributes);
      } else {
        resolve(viewAttributes);
      }
    } else {
      if (!IsNullOrWhiteSpace(moduleName)) {
        return repositoryActions
          .postDataAndGetResponse(
            //`${Constants.baseAPIUrl}${moduleName}/Fields`,// if viewId is give change the url accordingly
            url,
            entityListRequest,
            null,
            ContentType.applicationJson,
            false
          )
          .then((response) => {
            if (
              isNotNullAndUndefined(response) &&
              isNotNullAndUndefined(response.data) &&
              isNotNullAndUndefined(response.data.Entities)
            ) {
              const viewAttributes: QueryAttributeJM[] = response.data.Entities;
              //setSessionStorage('viewAttribs_' + (viewId || this.typeName),true, JSON.stringify(viewAttributes));
              setSessionStorage(
                StorageKey.viewAttribs_modulename +
                  (isNotNullAndUndefined(viewId) ? viewId : moduleName),
                true,
                JSON.stringify(viewAttributes)
              );

              const uniqViewAttributes: QueryAttributeJM[] =
                R.uniq(viewAttributes);
              resolve(uniqViewAttributes);
            } else {
              resolve(null);
            }
          })
          .catch((error) => error);
      } else {
        resolve(null);
      }
    }
  });
};

export const getAllAttributes = (moduelName?: string) => {
  return new Promise<AttributeRelatedList>((resolve) => {
    const allAttributesStorage = getSessionStorage(
      StorageKey.allAttribs_modulename + moduelName,
      true
    );
    if (
      isNotNullAndUndefined(allAttributesStorage) &&
      !IsNullOrWhiteSpace(allAttributesStorage)
    ) {
      const viewAttributes: AttributeRelatedList =
        JSON.parse(allAttributesStorage);
      resolve(viewAttributes);
    } else {
      if (!IsNullOrWhiteSpace(moduelName)) {
        let entityListRequest: EntityListRequest = new EntityListRequest();
        entityListRequest.EntityName = moduelName;
        return repositoryActions
          .postDataAndGetResponse(
            `Attribute/ListAll`,
            entityListRequest,
            null,
            ContentType.applicationJson,
            false
          )
          .then((response) => {
            if (
              isNotNullAndUndefined(response) &&
              isNotNullAndUndefined(response.data)
            ) {
              let attributeRelatedList: AttributeRelatedList = {
                Attributes: response.data.Attributes,
                EntityRelations: response.data.EntityRelations,
              };
              if (isNotNullAndUndefined(attributeRelatedList.Attributes)) {
                attributeRelatedList.Attributes.sort(function (x, y) {
                  return isNotNullAndUndefined(x.AttributeSettings)
                    ? x.DisplayOrder > y.DisplayOrder
                      ? 1
                      : -1
                    : -1;
                });

                attributeRelatedList.Attributes.forEach((item, index) => {
                  if (isNullOrUndefined(item.AttributeSettings)) {
                    attributeRelatedList.Attributes[index].AttributeSettings = {
                      IsRequired: false,
                      DefaultValue: "",
                      IsPrimary: false,
                      IsReadonly: false,
                      UIType: undefined,
                    };
                  }
                });

                //setSessionStorage('viewAttribs_' + (viewId || this.typeName),true, JSON.stringify(viewAttributes));
                setSessionStorage(
                  StorageKey.allAttribs_modulename + moduelName,
                  true,
                  JSON.stringify(attributeRelatedList)
                );
                resolve(attributeRelatedList);
              } else {
                resolve(attributeRelatedList);
              }
            } else {
              resolve(null);
            }
          })
          .catch((error) => error);
      } else {
        resolve(null);
      }
    }
  });
};

export const getQueryAttribute = (moduleName: string, fieldName: string) => {
  return new Promise<QueryAttributeJM>((resolve) => {
    if (isNotNullAndUndefined(moduleName) && isNotNullAndUndefined(fieldName)) {
      getFields(moduleName).then((attribRow) => {
        let attributeRow: QueryAttributeJM[] = attribRow as QueryAttributeJM[];
        let choiceListRow: QueryAttributeJM =
          attributeRow &&
          attributeRow.find((x) => x.PropertyName === fieldName);
        resolve(choiceListRow);
      });
    } else {
      resolve(null);
    }
  });
};

export const getAttributeValueJson = (
  moduleName?: string,
  fieldName?: string
) => {
  return new Promise<ValueJson[]>((resolve) => {
    getQueryAttribute(moduleName, fieldName).then((attribRow) => {
      const valueJsonRow = attribRow && attribRow.ValueJson;
      resolve(valueJsonRow);
    });
  });
};

export const getRecordCount = (
  moduelName?: string,
  viewUID?: string,
  relatedField?: string,
  relatedFieldValue?: string
) => {
  // let url = `${Constants.baseAPIUrl + getURLPrefixByModuleName(moduleName)
  //   }/RelatedList`;

  let url = `${Constants.baseAPIUrl}DataList/RelatedList`;

  var listRequest: ListServiceRequest = {
    Skip: 0,
    Take: 10,
    ViewUID: viewUID,
  };

  let additionalParams = [
    {
      key: "RelatedField",
      value: relatedField,
    },
    {
      key: "RelatedFieldValue",
      value: relatedFieldValue,
    },
  ];

  if (isNotNullAndUndefined(additionalParams) && additionalParams.length > 0) {
    additionalParams.forEach((objProp) => {
      listRequest[objProp.key] = objProp.value;
    });
  }

  return new Promise<number>((resolve) => {
    return repositoryActions
      .postDataAndGetResponse(
        url,
        listRequest,
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        resolve(response.data.TotalRows);
      })
      .catch((error) => error);
  });
};

export const Logout = () => {
  let url = "User/Logout";
  return repositoryActions
    .postDataAndGetResponse(url, null, null, ContentType.applicationJson)
    .then((response) => {
      setSessionStorage(RMStorageKey.isAdmin, false, "false");
      setSessionStorage(
        RMStorageKey.currentMenuCategory,
        false,
        MenuCategory.Default
      );
      //mslaInstance.logOutAD();
      //mslaInstance.logoutRedirect();

      const account: AccountInfo = msalInstance.getActiveAccount();
      //console.log({ account });
      let accountType = "";
      if (
        account &&
        account.idTokenClaims &&
        account.idTokenClaims.idp &&
        account.idTokenClaims.idp.includes("login.microsoftonline.com")
      ) {
        accountType = "microsoft";
      }

      if (accountType === "microsoft") {
        const logoutUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=${window.location.origin}`;
        msalInstance.logoutRedirect({
          ...loginRequest,
          account: account,
          //postLogoutRedirectUri: `${window.location.origin}/logout/${accountType}`
          postLogoutRedirectUri: logoutUrl,
        });
      } else {
        msalInstance.logoutRedirect({
          ...loginRequest,
          account: account,
        });
      }
      //msalInstance.logOutAD();
    })
    .catch((error) => error);
};

export const refreshLeftMenu = (moduleName?: string) => {
  let rafMenuModelStorage: RAFMenuModel = getJSONFromSessionStorage(
    StorageKey.MenuModel,
    true
  ) as RAFMenuModel;
  let menuRow: MenuRow = rafMenuModelStorage.MainMenu.find(
    (x) => x.Name === moduleName
  );
  let recentViews =
    JSON.parse(
      getSessionStorage(StorageKey.recentViewId_modulename + moduleName, true)
    ) || [];
  let recentViewId = recentViews["viewId"];
  if (isNotNullAndUndefined(menuRow)) {
    if (isNotNullAndUndefined(recentViewId)) {
      removeSessionStorage("recentClickedMenu");
      window.location.href =
        window.location.origin + menuRow.Url + recentViewId;
    } else {
      getUserViewsByModuleName(moduleName).then((allViews) => {
        window.location.href =
          window.location.origin + menuRow.Url + allViews[0].UID;
      });
    }
  }
};

export const refreshLeftMenuViews = (moduleName?: string) => {
  let rafMenuModelStorage: RAFMenuModel = getJSONFromSessionStorage(
    StorageKey.MenuModel,
    true
  ) as RAFMenuModel;
  let menuRow: MenuRow = rafMenuModelStorage.MainMenu.find(
    (x) => x.Name === moduleName
  );
  if (isNullOrUndefined(menuRow)) {
    menuRow = rafMenuModelStorage.SystemMenu.find((x) => x.Name === moduleName);
  }
  let recentViews =
    JSON.parse(
      getSessionStorage(StorageKey.recentViewId_modulename + moduleName, true)
    ) || [];
  let recentViewId = recentViews["viewId"];
  if (isNotNullAndUndefined(menuRow)) {
    if (isNullOrUndefined(recentViewId)) {
      getUserViewsByModuleName(moduleName).then(() => {});
    }
  }
};

export const getAllUsers = () => {
  return new Promise<LookUpRow[]>((resolve) => {
    let url = `${Constants.baseAPIUrl}User/LookUp`;
    const allUsersStorage = getSessionStorage(StorageKey.allUsers, true);
    if (
      isNotNullAndUndefined(allUsersStorage) &&
      !IsNullOrWhiteSpace(allUsersStorage) &&
      isNotNullAndUndefined(JSON.parse(allUsersStorage))
    ) {
      const allUsers: LookUpRow[] = JSON.parse(allUsersStorage);
      resolve(allUsers);
    } else {
      return repositoryActions
        .postDataAndGetResponse(
          url,
          { Skip: 0 },
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data) &&
            isNotNullAndUndefined(response.data.Entities)
          ) {
            const allUsers: LookUpRow[] = response.data.Entities;
            setSessionStorage(
              StorageKey.allUsers,
              true,
              JSON.stringify(allUsers)
            );
            resolve(allUsers);
          } else {
            resolve(null);
          }
        })
        .catch((error) => error);
    }
  });
};

export const getAllUserGroups = () => {
  return new Promise<LookUpRow[]>((resolve) => {
    let url = `${Constants.baseAPIUrl}Team/LookUp`;
    const allUserGroupsStorage = getSessionStorage(
      StorageKey.allUserGroups,
      true
    );
    if (
      isNotNullAndUndefined(allUserGroupsStorage) &&
      !IsNullOrWhiteSpace(allUserGroupsStorage) &&
      isNotNullAndUndefined(JSON.parse(allUserGroupsStorage))
    ) {
      const allUserGroups: LookUpRow[] = JSON.parse(allUserGroupsStorage);
      resolve(allUserGroups);
    } else {
      return repositoryActions
        .postDataAndGetResponse(
          url,
          { Skip: 0 },
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data) &&
            isNotNullAndUndefined(response.data.Entities)
          ) {
            const allUserGroups: LookUpRow[] = response.data.Entities;
            setSessionStorage(
              StorageKey.allUserGroups,
              true,
              JSON.stringify(allUserGroups)
            );
            resolve(allUserGroups);
          } else {
            resolve(null);
          }
        })
        .catch((error) => error);
    }
  });
};

const tabActionNext: TabActionSettingsModel = {
  effect: "FadeIn",
  duration: 600,
  easing: "ease",
};
const tabActionPrevious: TabActionSettingsModel = {
  effect: "FadeIn",
  duration: 600,
  easing: "ease",
};
export const tabAnimationSettings: TabAnimationSettingsModel = {
  previous: tabActionPrevious,
  next: tabActionNext,
};

export const showProgress = (
  target?: string | HTMLElement,
  isProgressBody?: boolean,
  progressClassName?: string,
  loadingImage?: string
) => {
  const progressOverlayDivId = "rafProgress_" + Guid.newGuid();
  let targetDiv = document.querySelector("body") as HTMLElement;
  if (isNotNullAndUndefined(target)) {
    if (target instanceof HTMLElement) {
      targetDiv = target;
    } else {
      targetDiv = document.querySelector(target);
    }
  }
  let progressOverlayDiv = document.querySelector<HTMLElement>(
    "#" + progressOverlayDivId
  );

  if (isNotNullAndUndefined(progressOverlayDiv)) {
  } else {
    const progressElement = document.createElement("div");
    progressElement.setAttribute("id", progressOverlayDivId);
    if (isNotNullAndUndefined(isProgressBody) && isProgressBody === true) {
      progressElement.setAttribute("class", "progressBody");
    } else if (isNotNullAndUndefined(progressClassName)) {
      progressElement.setAttribute("class", progressClassName);
    } else {
      progressElement.setAttribute("class", "progressDiv");
    }
    progressElement.setAttribute("style", 'background: "transparent"');
    let loaderImage = isNotNullAndUndefined(loadingImage)
      ? loadingImage
      : acloading;
    progressElement.innerHTML =
      '<div class="d-flex flex-column" > <img src=' +
      loaderImage +
      ' width="52" /> <span class="loadingText">Loading...</span> </div>';
    if (isNotNullAndUndefined(targetDiv)) {
      targetDiv.appendChild(progressElement);
    } else {
      document.body.appendChild(progressElement);
    }
  }

  progressOverlayDiv = document.querySelector("#" + progressOverlayDivId);
  if (isNotNullAndUndefined(progressOverlayDiv)) {
    const destination: HTMLElement = targetDiv;
    // document.querySelector<HTMLElement>(target);
    if (isNotNullAndUndefined(destination)) {
      progressOverlayDiv.style.top = destination.offsetTop.toString();
      progressOverlayDiv.style.left = destination.offsetLeft.toString();
    }
  }
  //document.body.classList.add('overflow-hidden');

  return progressOverlayDiv;
};

export const hideProgress = (progressOverlayDiv) => {
  //const progressOverlayDivId = "progressOverlayDiv";
  //let progressOverlayDiv = document.querySelector<HTMLElement>("#" + progressOverlayDivId);
  if (isNotNullAndUndefined(progressOverlayDiv)) {
    progressOverlayDiv.remove();
  }
  //document.body.classList.remove('overflow-hidden');
};

export const hideAllProgress = () => {
  let progressOverlayDiv = document.querySelectorAll('div[id^="rafProgress_"]');
  if (isNotNullAndUndefined(progressOverlayDiv)) {
    progressOverlayDiv.forEach((item) => {
      item.remove();
    });
  }
};

let gridstates: { key: string; gridModel: GridModel; ruleModel: RuleModel }[] =
  [];
export const SetGridState = (
  key: string,
  gridModel: GridModel,
  ruleModel: RuleModel
) => {
  let objState = gridstates.find((x) => x.key === key);
  if (isNotNullAndUndefined(objState) && isNotNullAndUndefined(objState.key)) {
    gridstates.find((x) => x.key === key).gridModel = gridModel;
    gridstates.find((x) => x.key === key).ruleModel = ruleModel;
  } else {
    gridstates.push({ key: key, gridModel: gridModel, ruleModel: ruleModel });
  }
};

export const ClearGridState = () => {
  gridstates = [];
};

export const GetGridState = (
  key: string
): { gridModel: GridModel; ruleModel: RuleModel } => {
  let objState = gridstates.find((x) => x.key === key);
  if (isNotNullAndUndefined(objState) && isNotNullAndUndefined(objState.key)) {
    return { gridModel: objState.gridModel, ruleModel: objState.ruleModel };
  } else {
    return null;
  }
};

export const GetGridModelFromGridState = (key: string): GridModel => {
  let gridState = GetGridState(key);
  if (isNotNullAndUndefined(gridState)) {
    return gridState.gridModel;
  } else {
    return null;
  }
};

export function showHideGridFilter(
  visible: "show" | "hide" | "auto",
  grid?: any
) {
  if (isNotNullAndUndefined(grid) && isNotNullAndUndefined(grid.element)) {
    let qbSectionOutter = grid.element.closest("#qb-section-outter");
    let qbSection =
      qbSectionOutter && qbSectionOutter.querySelector("#qb-section");
    // let divGridParent = grid.element.closest("#divGridParent")
    if (
      isNotNullAndUndefined(qbSection)
      //  && isNotNullAndUndefined(divGridParent)
    ) {
      let qbSectionClassname = qbSection.classList;
      // let qbSectionHeight = qbSection.clientHeight
      if (visible === "show") {
        qbSection.classList.add("show");
        // divGridParent.style.marginTop = `${qbSectionHeight}px`
      } else if (visible === "hide") {
        qbSection.classList.remove("show");
        // divGridParent.style.marginTop = `0px`
      } else if (visible === "auto") {
        if (qbSectionClassname.contains("show")) {
          qbSection.classList.remove("show");
          // divGridParent.style.marginTop = `0px`
        } else {
          qbSection.classList.add("show");
          // divGridParent.style.marginTop = `${qbSectionHeight}px`
        }
      }
    }
  }
}

export const getCustomFilterBySFRules = (rule: RuleModel): RAFCustomFilter => {
  let outVal: RAFCustomFilter = {};
  if (isNotNullAndUndefined(rule)) {
    if (rule.rules && isNotNullAndUndefined(rule.rules)) {
      outVal.Condition =
        rule.condition === "and"
          ? RAFOperator.AndCondition
          : RAFOperator.OrCondition;
      outVal.Rules = [];
      const ruleModels: RuleModel[] = rule.rules;
      for (var i = 0; i < ruleModels.length; i++) {
        outVal.Rules.push(getCustomFilterBySFRules(ruleModels[i]));
      }
    } else {
      let objField: RAFCustomFilter = {};
      objField.Field = rule.field;
      objField.Type = rule.type;
      objField.Operator = getRAFOperatorBySFOperator(rule.operator);
      let filterVal: string[] = [];
      if (isNotNullAndUndefined(rule.value)) {
        if (isArray(rule.value)) {
          filterVal = rule.value as string[];
        } else {
          filterVal.push(rule.value.toString());
        }
      }
      objField.Value = filterVal;
      outVal = objField;
    }
  }
  return outVal;
};

export const getSFRulesByCustomFilter = (rule: RAFCustomFilter): RuleModel => {
  let outVal: RuleModel = {};
  if (isNotNullAndUndefined(rule)) {
    if (rule.Rules && isNotNullAndUndefined(rule.Rules)) {
      outVal.condition =
        rule.Condition === RAFOperator.AndCondition ? "and" : "or";
      outVal.rules = [];
      const ruleModels: RAFCustomFilter[] = rule.Rules;
      for (var i = 0; i < ruleModels.length; i++) {
        outVal.rules.push(getSFRulesByCustomFilter(ruleModels[i]));
      }
    } else {
      let objField: RuleModel = {};
      objField.field = rule.Field;
      objField.type =
        rule.Type === SFColumnType.datetime ? SFColumnType.date : rule.Type;
      objField.operator = getSFOperatorByRAFOperator(rule.Operator);
      if (
        isNotNullAndUndefined(rule.Value) &&
        isNotNullAndUndefined(rule.Value[0])
      )
        objField.value = rule.Value.length > 1 ? rule.Value : rule.Value[0];
      outVal = objField;
    }
  }
  return outVal;
};

export function getSessionStorage(
  storageKey?: string,
  includeDomainName?: boolean
) {
  let outVal;
  if (isNotNullAndUndefined(includeDomainName) && includeDomainName === true) {
    outVal = sessionStorage.getItem(
      msalInstance.currentTenantName + storageKey
    );
  } else {
    outVal = sessionStorage.getItem(storageKey);
  }
  return outVal;
}

export function setSessionStorage(
  storageKey?: string,
  includeDomainName?: boolean,
  value?: any
) {
  if (isNotNullAndUndefined(includeDomainName) && includeDomainName === true) {
    sessionStorage.setItem(msalInstance.currentTenantName + storageKey, value);
  } else {
    sessionStorage.setItem(storageKey, value);
  }
}

export function removeSessionStorage(
  storageKey?: string,
  includeDomainName?: boolean
) {
  if (isNotNullAndUndefined(includeDomainName) && includeDomainName === false) {
    sessionStorage.removeItem(storageKey);
  } else {
    sessionStorage.removeItem(msalInstance.currentTenantName + storageKey);
  }
}

export function getViewMenuDataSource(moduleName?: string) {
  return new Promise<RAFViewRow[]>((resolve) => {
    getUserViewsByModuleName(moduleName).then((allViews) => {
      getReportListByModuleName(moduleName).then((allReportsResponse) => {
        const rafAllViews = allViews;
        let viewMenuDataSource: RAFViewRow[] = [];
        if (isNotNullAndUndefined(rafAllViews) && rafAllViews.length > 0) {
          rafAllViews.forEach((item) => {
            let viewData = { UID: item.UID, DisplayName: item.DisplayName };
            viewMenuDataSource.push(viewData);
          });
        }

        if (
          isNotNullAndUndefined(allReportsResponse) &&
          allReportsResponse.length > 0
        ) {
          allReportsResponse.forEach((item) => {
            let reportData = { UID: item.UID, DisplayName: item.Title };
            viewMenuDataSource.push(reportData);
          });
        }
        resolve(viewMenuDataSource);
      });
    });
  });
}

export const getAllForms = () => {
  let url = `FormLibrary/List`;
  let listServiceRequest = new ListServiceRequest();
  listServiceRequest.Skip = 0;
  listServiceRequest.Take = 0;
  //listServiceRequest.ViewName = "all_form_library";
  return new Promise<FormLibraryRow[]>((resolve) => {
    const allFormsStorage = getSessionStorage(RMStorageKey.all_Forms);
    if (
      isNotNullAndUndefined(allFormsStorage) &&
      !IsNullOrWhiteSpace(allFormsStorage) &&
      isNotNullAndUndefined(JSON.parse(allFormsStorage))
    ) {
      const allForms: FormLibraryRow[] = JSON.parse(allFormsStorage);
      resolve(allForms);
    } else {
      return repositoryActions
        .postDataAndGetResponse(
          url,
          listServiceRequest,
          null,
          ContentType.applicationJson,
          false
        )
        .then((response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data) &&
            isNotNullAndUndefined(response.data.Entities)
          ) {
            const allForms: FormLibraryRow[] = response.data.Entities;
            if (isNotNullAndUndefined(allForms) && allForms.length > 0) {
              setSessionStorage(
                RMStorageKey.all_Forms,
                false,
                JSON.stringify(allForms)
              );
            }
            resolve(allForms);
          } else {
            resolve(null);
          }
        })
        .catch((error) => error);
    }
  });
};

export const CheckFormExist = (formName?: string) => {
  return new Promise<boolean>((resolve) => {
    getAllForms().then((allForms) => {
      let selectedFormLibraryRow: FormLibraryRow =
        allForms && allForms.find((x) => x.Title === formName);
      if (isNotNullAndUndefined(selectedFormLibraryRow)) {
        resolve(true);
      } else {
        resolve(false);
      }
    });
  });
};

export function GetFieldsDisplayName(
  queryAttributes: QueryAttributeJM[],
  field: string,
  defaultValue: string,
  attributeRelatedList?: AttributeRelatedList
) {
  let displayLabel = defaultValue;
  if (isNotNullAndUndefined(defaultValue)) {
  } else {
    let fieldToSplit = field.split(".");
    let fieldString = isNotNullAndUndefined(fieldToSplit)
      ? fieldToSplit.splice(-1)
      : null;
    let fieldName = isNotNullAndUndefined(fieldString) ? fieldString[0] : field;
    const uniqueAllFields: QueryAttributeJM[] = isNotNullAndUndefined(
      queryAttributes
    )
      ? R.uniq(queryAttributes)
      : null;
    let objField: QueryAttributeJM = isNotNullAndUndefined(uniqueAllFields)
      ? uniqueAllFields.find((x) => x.PropertyName === fieldName)
      : null;

    if (isNotNullAndUndefined(objField)) {
      let attributeItem: AttributeRow =
        isNotNullAndUndefined(attributeRelatedList) &&
        isNotNullAndUndefined(attributeRelatedList.Attributes) &&
        attributeRelatedList.Attributes.length > 0
          ? attributeRelatedList.Attributes.find(
              (x) => x.UID === objField.AttributeUID
            )
          : null;

      if (
        isNotNullAndUndefined(attributeItem) &&
        isNotNullAndUndefined(attributeItem.UID) &&
        isNotNullAndUndefined(attributeItem.Description)
      ) {
        displayLabel = attributeItem.Description;
      } else if (isNotNullAndUndefined(objField.DisplayName)) {
        displayLabel = objField.DisplayName;
      } else {
        displayLabel = objField.FieldName;
      }
    } else {
      displayLabel = fieldName;
    }
  }
  return displayLabel;
}

export function GetSelectedField(
  queryAttributes: QueryAttributeJM[],
  field: string,
  attributeRelatedList?: AttributeRelatedList
) {
  let selectedQueryAttribute =
    queryAttributes &&
    queryAttributes.find((x) => x.PropertyName === field.toString());

  let selectedField =
    isNotNullAndUndefined(selectedQueryAttribute) &&
    attributeRelatedList.Attributes &&
    attributeRelatedList.Attributes.find(
      (x) => x.UID === selectedQueryAttribute.AttributeUID
    );
  return selectedField;
}

export function GetSelectedFieldDescription(
  queryAttributes: QueryAttributeJM[],
  field: string,
  attributeRelatedList?: AttributeRelatedList
) {
  let selectedQueryAttribute =
    queryAttributes &&
    queryAttributes.find((x) => x.PropertyName === field.toString());

  let selectedField =
    isNotNullAndUndefined(selectedQueryAttribute) &&
    attributeRelatedList.Attributes &&
    attributeRelatedList.Attributes.find(
      (x) => x.UID === selectedQueryAttribute.AttributeUID
    );
  if (
    isNotNullAndUndefined(selectedField) &&
    isNotNullAndUndefined(selectedField.Description)
  ) {
    return selectedField.Description;
  } else {
    return null;
  }
}

export function GetSelectedFieldDescriptionStyle(
  queryAttributes: QueryAttributeJM[],
  field: string,
  attributeRelatedList?: AttributeRelatedList
) {
  let selectedQueryAttribute =
    queryAttributes &&
    queryAttributes.find((x) => x.PropertyName === field.toString());

  let selectedField =
    isNotNullAndUndefined(selectedQueryAttribute) &&
    attributeRelatedList.Attributes &&
    attributeRelatedList.Attributes.find(
      (x) => x.UID === selectedQueryAttribute.AttributeUID
    );
  if (
    isNotNullAndUndefined(selectedField) &&
    isNotNullAndUndefined(selectedField.AttributeSettings) &&
    isNotNullAndUndefined(selectedField.AttributeSettings.DescriptionAsLabel) &&
    selectedField.AttributeSettings.DescriptionAsLabel === true
  ) {
    return true;
  } else {
    return false;
  }
}

export const getRelatedRecords = (
  moduleName?: string,
  viewUID?: string,
  relatedField?: string,
  relatedFieldValue?: string,
  take?: number,
  skip?: number,
  viewName?: string
) => {
  let url = `${
    Constants.baseAPIUrl + getURLPrefixByModuleName(moduleName)
  }/RelatedList`;

  const listRequest: ListServiceRequest = {
    Skip: skip ?? 0,
    Take: take ?? 0,
  };

  if (isNotNullAndUndefined(viewUID)) {
    listRequest.ViewUID = viewUID;
  }
  if (isNotNullAndUndefined(viewName)) {
    listRequest.ViewName = viewName;
  }

  let additionalParams = [
    {
      key: "RelatedField",
      value: relatedField,
    },
    {
      key: "RelatedFieldValue",
      value: relatedFieldValue,
    },
  ];

  if (isNotNullAndUndefined(additionalParams) && additionalParams.length > 0) {
    additionalParams.forEach((objProp) => {
      listRequest[objProp.key] = objProp.value;
    });
  }

  return new Promise<any>((resolve) => {
    return repositoryActions
      .postDataAndGetResponse(
        url,
        listRequest,
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (isNotNullAndUndefined(response)) {
          if (response.status === 204) {
            resolve(null);
          } else if (isNotNullAndUndefined(response.data)) {
            if (isNotNullAndUndefined(response.data.Entity)) {
              resolve(response.data.Entity);
            } else if (isNotEmptyArray(response.data.ResultTable)) {
              resolve(response.data.ResultTable);
            } else if (isNotNullAndUndefined(response.data.Entities)) {
              resolve(response.data.Entities);
            } else {
              resolve(null);
            }
          }
        } else {
          resolve(null);
        }
      })
      .catch((error) => resolve(null));
  });
};

export function navigateToExternalUrl(
  url?: string,
  shouldOpenNewTab?: boolean
) {
  if (isNotNullAndUndefined(shouldOpenNewTab) && shouldOpenNewTab === true) {
    window.open(url, "_blank");
  } else {
    window.location.href = url;
  }
}

// export const useOutsideClick = (callback) => {
//     const ref = useRef();
//     useEffect(() => {
//         const handleClick = (event) => {
//             if (ref.current && !(ref.current as any).contains(event.target)) {
//                 callback();
//             }
//         }; document.addEventListener('click', handleClick, true);
//         return () => {
//             document.removeEventListener('click', handleClick, true);
//         };
//     }, [ref]);
//     return ref;
// };

export const closeAllSFPopups = () => {
  // let eTooltipTemplate = document.getElementsByClassName('e-tooltip-template-css')
  // for (let i = 0; i < eTooltipTemplate.length; i++) {
  //     console.log("eTooltipTemplate[i]", eTooltipTemplate[i]['ej2_instances'][0])
  //     //eTooltipTemplate[i].classList.remove("e-popup-open");
  //     //eTooltipTemplate[i].classList.add("e-popup-close");

  //     // eTooltipTemplate[i]['ej2_instances'][0].element.classList.remove('e-popup-open');
  //     // eTooltipTemplate[i]['ej2_instances'][0].element.classList.add('e-popup-close');
  //     // eTooltipTemplate[i]['ej2_instances'][0].element.remove();
  // }

  //return;
  let eOpenPopups = document.getElementsByClassName("e-popup-open");
  console.log("eOpenPopups", eOpenPopups);
  for (let i = 0; i < eOpenPopups.length; i++) {
    console.log(
      "eOpenPopups[i]['ej2_instances'][0] ",
      eOpenPopups[i]["ej2_instances"][0]
    );
    try {
      const objPopup = eOpenPopups[i]["ej2_instances"][0] as Popup;
      const objElement = objPopup.element;
      const objPopupTarget = document.querySelector(
        `[data-tooltip-id*="${objElement.id}"]`
      );
      objPopupTarget.removeAttribute("data-tooltip-id");
      objPopupTarget.removeAttribute("aria-describedby");
      console.log("objPopupTarget", objPopupTarget);
      // objPopup.close();
      // objPopup.hide();
      // objPopup.refresh();
      objPopup.element.classList.remove("e-popup-open");
      objPopup.element.classList.add("e-popup-close");
      // objPopup.trigger('close');
      // objPopup.refresh();
      // objPopup.element.remove();
      // console.log('2', objPopup);
      // console.log('objPopup.element.classList', objPopup.element.classList);
    } catch (e) {
      console.log(e);
    }
  }
};

export const onBodyClick = (e) => {
  console.log("onBodyClick", e);
  if (e.target.classList.contains("e-popup")) return;
  if (isNotNullAndUndefined(e.target.closest("e-popup-open"))) return;
  closeAllSFPopups();
};
// document.body.addEventListener('click', onBodyClick);

export const useOutsideClick = (callback) => {
  const ref = React.useRef();
  React.useEffect(() => {
    const handleClick = (event) => {
      if (ref.current && !(ref.current as any).contains(event.target)) {
        callback();
      }
    };
    document.addEventListener("click", handleClick, true);
    return () => {
      document.removeEventListener("click", handleClick, true);
    };
  }, [ref]);
  return ref;
};

export const IsSuperAdmin = (): boolean => {
  const currentUser = JSON.parse(getSessionStorage(StorageKey.currentUser));
  const permissionGroup = isNotNullAndUndefined(currentUser)
    ? currentUser.PermissionGroup
    : null;
  if (
    permissionGroup === SecureAppAdministrator ||
    permissionGroup === SecureSuperAdministrator
  ) {
    return true;
  } else if (IsSuperAdminQueryString() || IsAdminQueryString()) {
    return true;
  } else {
    return false;
  }
};

export const IsSuperAdminQueryString = (): boolean => {
  const myURl = getQueryString("admin");
  if (isNotNullAndUndefined(myURl) && myURl === "1") {
    return true;
  } else {
    return false;
  }
};

export const IsAdminQueryString = (): boolean => {
  const myURl = getQueryString("admin");
  if (isNotNullAndUndefined(myURl) && myURl === "2024") {
    return true;
  } else {
    return false;
  }
};

export function getAllLookUpList(
  entityName: string,
  url: string,
  customFilter?: RAFCustomFilter,
  sort?: RAFSort
) {
  return new Promise<LookUpRow[]>((resolve) => {
    if (isNotNullAndUndefined(url)) {
      let listServiceRequest = new ListServiceRequest();
      listServiceRequest.Skip = 0;
      listServiceRequest.Take = 0;

      if (isNotNullAndUndefined(sort)) {
        let sortQuery: string[] = [];
        if (isNotNullAndUndefined(sort) && isNotNullAndUndefined(sort.field)) {
          sortQuery.push(
            sort.order === "descending" ? `${sort.field} desc` : sort.field
          );
        }

        listServiceRequest.Sort = sortQuery;
      }

      if (isNotNullAndUndefined(customFilter))
        listServiceRequest.CustomFilter = customFilter;

      if (isNotNullAndUndefined(isNotNullAndUndefined(entityName)))
        listServiceRequest.EntityName = entityName;

      return repositoryActions
        .postDataAndGetResponse(
          url,
          listServiceRequest,
          null,
          ContentType.applicationJson,
          false
        )
        .then((response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data)
          ) {
            resolve(response.data.Entities);
          } else {
            resolve(null);
          }
        })
        .catch((error) => error);
    } else {
      resolve(null);
    }
  });
}

export const defaultViewDataColumns = (moduleName: string) => {
  let dataColumns: string[] = [];
  if (isNotNullAndUndefined(moduleName)) {
    if (moduleName === RAFEntityName.ContentLibrary) {
      dataColumns = [
        "FileSize",
        "IsExternal",
        "FileName",
        "Entity",
        "RecordCategory",
        "DisplayName",
        "DocumentType",
        "ParentUID",
        "CreatedBy",
        "CreatedDate",
        "LastActivityDate",
        "ModifiedDate",
        "ModifiedBy",
      ];
    }
  }

  return dataColumns;
};

export const setImpersonateUserDefinition = (
  userDefinition: UserDefinition
) => {
  setSessionStorage(
    StorageKey.userDefinition,
    false,
    JSON.stringify(userDefinition)
  );
};

export const getImpersonateUserDefinition = (): UserDefinition => {
  const userDefinition = getSessionStorage(StorageKey.userDefinition);
  const retVal: UserDefinition = JSON.parse(userDefinition);
  return retVal;
};

export const isRAFMaxDate = (dateValue, convertDateValue) => {
  let isMaxDate = false;
  if (
    isNotNullAndUndefined(dateValue) &&
    isNotNullAndUndefined(convertDateValue)
  ) {
    if (
      Constants.MaxDate.valueOf() === dateValue.valueOf() ||
      moment(dateValue).isSame(Constants.MaxDate, "day") ||
      Constants.MaxDate.valueOf() === convertDateValue.valueOf() ||
      moment(convertDateValue).isSame(Constants.MaxDate, "day")
    ) {
      isMaxDate = true;
    }
  }
  return isMaxDate;
};

export const isRAFMinDate = (dateValue, convertDateValue) => {
  let isMinDate = false;
  if (
    isNotNullAndUndefined(dateValue) &&
    isNotNullAndUndefined(convertDateValue)
  ) {
    if (
      Constants.MinDate.valueOf() === dateValue.valueOf() ||
      moment(dateValue).isSame(Constants.MinDate, "day") ||
      Constants.MinDate.valueOf() === convertDateValue.valueOf() ||
      moment(convertDateValue).isSame(Constants.MinDate, "day")
    ) {
      isMinDate = true;
    }
  }

  return isMinDate;
};

export const switchPortalTenant = (selectedTenantId: string) => {
  return new Promise<boolean>((resolve) => {
    resolve(false);
    return;
    // if (isNotNullAndUndefined(selectedTenantId)) {
    //   if (msalInstance.currentTenantId !== selectedTenantId) {
    //     let allUserTenants = msalInstance.currentTenants;
    //     let selectedTenant: TenantRow = allUserTenants && allUserTenants.find(x => x.UID === selectedTenantId);

    //     let logInAsRequest: LogInAsRequest = new LogInAsRequest();
    //     //logInAsRequest.UserUID = msalInstance.currentUserId;
    //     logInAsRequest.TenantUID = selectedTenant.UID;

    //     let currentUser: UserInfoRow = JSON.parse(getSessionStorage(StorageKey.currentUser));
    //     if (isNotNullAndUndefined(currentUser) && isNotNullAndUndefined(currentUser.UserUID)) {
    //       currentUser.TenantName = selectedTenant.TenantName;
    //       currentUser.TenantUID = selectedTenant.UID;
    //       currentUser.PortalUID = null;
    //       currentUser.Portal = null;
    //     }

    //     postDataAndGetResponse(
    //       'User/LogInAs',
    //       logInAsRequest,
    //       null,
    //       ContentType.applicationJson,
    //       false
    //     )
    //       .then(() => {
    //         window.localStorage.setItem(StorageKey.currentTenant, JSON.stringify({ UID: selectedTenant.UID, DisplayName: selectedTenant.DisplayName, TenantName: selectedTenant.TenantName, SubDomain: selectedTenant.SubDomain }));
    //         removeSessionStorage(StorageKey.currentUser, false);
    //         resolve(true);
    //       })
    //       .catch((error) => error);
    //   } else {
    //     resolve(false);
    //   }
    // }
  });
};

export const getFieldsAPI = (moduleName?: string, viewId?: string) => {
  let url = `${Constants.baseAPIUrl}Attribute/ListFields`;
  let entityListRequest = new EntityListRequest();
  entityListRequest.EntityName = moduleName;
  return new Promise<QueryAttributeJM[]>((resolve) => {
    if (!IsNullOrWhiteSpace(moduleName)) {
      const viewAttributesStorage = getSessionStorage(
        StorageKey.viewAttribs_modulename +
          (isNotNullAndUndefined(viewId) ? viewId : moduleName),
        true
      );
      //console.log('viewAttribs_' + (typeName));
      if (
        isNotNullAndUndefined(viewAttributesStorage) &&
        !IsNullOrWhiteSpace(viewAttributesStorage)
      ) {
        const viewAttributes: QueryAttributeJM[] = JSON.parse(
          viewAttributesStorage
        );
        if (isNotNullAndUndefined(viewAttributes)) {
          const uniqViewAttributes: QueryAttributeJM[] = R.uniq(viewAttributes);
          resolve(uniqViewAttributes);
        } else {
          resolve(viewAttributes);
        }
      } else {
        return repositoryActions
          .postDataAndGetResponse(
            //`${Constants.baseAPIUrl}${moduleName}/Fields`,// if viewId is give change the url accordingly
            url,
            entityListRequest,
            null,
            ContentType.applicationJson,
            false
          )
          .then((response) => {
            if (
              isNotNullAndUndefined(response) &&
              isNotNullAndUndefined(response.data) &&
              isNotNullAndUndefined(response.data.Entities)
            ) {
              const viewAttributes: QueryAttributeJM[] = response.data.Entities;
              //setSessionStorage('viewAttribs_' + (viewId || this.typeName),true, JSON.stringify(viewAttributes));
              setSessionStorage(
                StorageKey.viewAttribs_modulename +
                  (isNotNullAndUndefined(viewId) ? viewId : moduleName),
                true,
                JSON.stringify(viewAttributes)
              );

              const uniqViewAttributes: QueryAttributeJM[] =
                R.uniq(viewAttributes);
              resolve(uniqViewAttributes);
            } else {
              resolve(null);
            }
          });
      }
    } else {
      resolve(null);
    }
  });
};

export const getAllFieldsByEntityUID = (entityUID: string) => {
  let url = `${Constants.baseAPIUrl}Attribute/ListFields`;
  let entityListRequest = new EntityListRequest();
  entityListRequest.EntityUID = entityUID;
  return new Promise<QueryAttributeJM[]>((resolve) => {
    if (!IsNullOrWhiteSpace(entityUID)) {
      const viewAttributesStorage = getSessionStorage(
        StorageKey.viewAttribs_modulename + entityUID,
        true
      );
      //console.log('viewAttribs_' + (typeName));
      if (
        isNotNullAndUndefined(viewAttributesStorage) &&
        !IsNullOrWhiteSpace(viewAttributesStorage)
      ) {
        const viewAttributes: QueryAttributeJM[] = JSON.parse(
          viewAttributesStorage
        );
        if (isNotNullAndUndefined(viewAttributes)) {
          const uniqViewAttributes: QueryAttributeJM[] = R.uniq(viewAttributes);
          resolve(uniqViewAttributes);
        } else {
          resolve(viewAttributes);
        }
      } else {
        return repositoryActions
          .postDataAndGetResponse(
            //`${Constants.baseAPIUrl}${moduleName}/Fields`,// if viewId is give change the url accordingly
            url,
            entityListRequest,
            null,
            ContentType.applicationJson,
            false
          )
          .then((response) => {
            if (
              isNotNullAndUndefined(response) &&
              isNotNullAndUndefined(response.data) &&
              isNotNullAndUndefined(response.data.Entities)
            ) {
              const viewAttributes: QueryAttributeJM[] = response.data.Entities;
              //setSessionStorage('viewAttribs_' + (viewId || this.typeName),true, JSON.stringify(viewAttributes));
              setSessionStorage(
                StorageKey.viewAttribs_modulename + entityUID,
                true,
                JSON.stringify(viewAttributes)
              );

              const uniqViewAttributes: QueryAttributeJM[] =
                R.uniq(viewAttributes);
              resolve(uniqViewAttributes);
            } else {
              resolve(null);
            }
          });
      }
    } else {
      resolve(null);
    }
  });
};

export const removeAllFormLibraryAndEntityItemsFromSessionStorage = () => {
  removeSessionStorage(RMStorageKey.all_Forms, true);
  const keys = Object.keys(sessionStorage);
  isNotEmptyArray(keys) &&
    keys.forEach((key) => {
      const formLibraryEntity =
        msalInstance.currentTenantName + RMStorageKey.FormLibraryEntity;
      const formLibraryCategoryType =
        msalInstance.currentTenantName + RMStorageKey.FormLibraryCategoryType;
      if (
        key.startsWith(formLibraryEntity) ||
        key.startsWith(formLibraryCategoryType)
      ) {
        if (IsNotNullOrWhiteSpace(key)) {
          sessionStorage.removeItem(key);
        }
      }
    });
};

export const getFormByEntity = (entityName?: string) => {
  return new Promise<FormLibraryRow>((resolve) => {
    if (IsNotNullOrWhiteSpace(entityName)) {
      const formLibraryEntityItem = getSessionStorage(
        RMStorageKey.FormLibraryEntity + entityName,
        true
      );

      const formLibrary: FormLibraryRow = IsNotNullOrWhiteSpace(
        formLibraryEntityItem
      )
        ? JSON.parse(formLibraryEntityItem)
        : null;

      if (
        isNotNullAndUndefined(formLibrary) &&
        isNotNullAndUndefined(formLibrary.UID)
      ) {
        resolve(formLibrary);
      } else {
        let url = `FormLibrary/List`;
        let listServiceRequest = new ListServiceRequest();
        listServiceRequest.Skip = 0;
        listServiceRequest.Take = 0;

        let relatedFilter: RAFCustomFilter = {};
        relatedFilter.Condition = "and";
        relatedFilter.Rules = [];

        let filter1: RAFCustomFilter = {};
        let filterVal1: string[] = [];
        filterVal1.push(entityName);
        filter1.Operator = RAFCustomOperator.Equal;
        filter1.Value = filterVal1;
        filter1.Field = propertyOf<FormLibraryRow>("Entity");
        relatedFilter.Rules.push(filter1);
        listServiceRequest.CustomFilter = relatedFilter;

        let sortBy = {
          field: propertyOf<FormLibraryRow>("Title"),
          order: "ascending",
        };
        let sortQuery: string[] = [];
        if (
          isNotNullAndUndefined(sortBy) &&
          isNotNullAndUndefined(sortBy.field)
        ) {
          sortQuery.push(
            sortBy.order === "descending"
              ? `${sortBy.field} desc`
              : sortBy.field
          );
        }
        listServiceRequest.Sort = sortQuery;

        return repositoryActions
          .postDataAndGetResponse(
            url,
            listServiceRequest,
            null,
            ContentType.applicationJson,
            false
          )
          .then((response) => {
            if (
              isNotNullAndUndefined(response) &&
              isNotNullAndUndefined(response.data) &&
              isNotEmptyArray(response.data.Entities)
            ) {
              const allForms: FormLibraryRow[] = response.data.Entities;
              const formLibrary: FormLibraryRow = allForms[0];
              if (
                isNotNullAndUndefined(formLibrary) &&
                isNotNullAndUndefined(formLibrary.UID)
              ) {
                setSessionStorage(
                  RMStorageKey.FormLibraryEntity + entityName,
                  true,
                  JSON.stringify(formLibrary)
                );
                resolve(formLibrary);
              } else {
                resolve(null);
              }
            } else {
              resolve(null);
            }
          });
      }
    } else {
      resolve(null);
    }
  });
};

export const RafClearDialogContent = (
  dialogRef: React.MutableRefObject<DialogComponent>,
  dialogComponent?: DialogComponent
) => {
  return new Promise<boolean>((resolve) => {
    let cleared = false;
    if (
      isNotNullAndUndefined(dialogRef) &&
      isNotNullAndUndefined(dialogRef.current)
    ) {
      dialogRef.current.content = null;
      cleared = true;
    }
    if (isNotNullAndUndefined(dialogComponent)) {
      dialogComponent.content = null;
      cleared = true;
    }
    if (cleared) {
      setTimeout(() => {
        resolve(cleared);
      }, 10);
    } else {
      resolve(cleared);
    }
  });
};

export function isArrayOfType(arr, type) {
  return arr.every((item) => typeof item === type);
}

export const hasDefaultModuleView = (ModuleName: string) => {
  //returns true for default modules
  let hasView = false;
  if (!IsNullOrWhiteSpace(ModuleName)) {
    const moduleName = ModuleName.toLocaleLowerCase();
    switch (moduleName) {
      case RAFEntityName.InspectionLibraryCategoryType:
      case RAFEntityName.Portal:
      case RAFEntityName.Tenant:
      case RAFEntityName.Incident:
      case RAFEntityName.Employee:
      case RAFEntityName.User:
      case RAFEntityName.Role:
        hasView = true;
        break;
      default:
        hasView = false;
        break;
    }
  }

  return hasView;
};

export function isHTML(str) {
  const htmlRegex = /<[^>]*>/g;
  return htmlRegex.test(str);
}
