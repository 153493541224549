import { RAFField } from "../models/Common/RAFField";
import { RAFEntityBase } from "../models/Common/RAFEntityBase";
import { RAFSectionFields } from "../models/Common/RAFSectionFields";

export const convertStateToArrayOfFormObjects = formObject => {
    const formElementsArray = [];
    for (let key in formObject) {
        formElementsArray.push({
            id: key,
            config: formObject[key]
        });
    }

    return formElementsArray;
};

/*
const checkValidity = (value, validation) => {
    let validationObject = {
        isValid: true,
        errorMessage: ''
    };

    if (validation) {
        if (validation.required) {
            validationObject.isValid = value.trim() !== '';
            validationObject.errorMessage = validationObject.isValid ? '' : 'Field is required';
        }

        if (validationObject.isValid && validation.maxLength) {
            validationObject.isValid = value.length <= 60;
            validationObject.errorMessage = 'Not allowed more than 60 charactes';
        }

        return validationObject;
    }
    else {
        return validationObject;
    }
}

export const executeValidationAndReturnFormElement = (event, updatedRAFForm, id, name, value) => {
    let formElement = { ...updatedRAFForm[id] };
    //formElement.value = id === 'dateOfBirth' ? event : event.target.value;
    if (value !== null && value !== undefined) {
        formElement.value = value;
    }
    else {
        formElement.value = formElement.element === 'datePicker' ? event : event.target.value;
    }
    formElement.touched = true;

    const validationResponse = checkValidity(formElement.value, formElement.validation);

    formElement.valid = validationResponse.isValid;
    formElement.errorMessage = validationResponse.errorMessage;

    return formElement;
}

export const countInvalidElements = (rafForm) => {
    let countInvalidElements = 0;
    for (let element in rafForm) {
        if (!rafForm[element].valid) {
            countInvalidElements = countInvalidElements + 1;
            break;
        }
    }
    return countInvalidElements;
}
*/

const checkValidity = (value, required, maxLength) => {
    let validationObject = {
        isValid: true,
        errorMessage: ""
    };

    if (required) {
        validationObject.isValid = value !== null && value !== undefined && value.trim() !== "";
        validationObject.errorMessage = validationObject.isValid ? "" : "Field is required";
    }

    if (validationObject.isValid && maxLength && maxLength > 0) {
        if (required) {
            validationObject.isValid = value !== null && value !== undefined && value.trim() !== "" && value.length <= maxLength;
        }
        else {
            validationObject.isValid = value === null || (value !== null && value !== undefined && value.length <= maxLength);
        }
        //validationObject.isValid = value !== null && value !== undefined && value.length <= maxLength;
        validationObject.errorMessage = "Not allowed more than 60 charactes";
    }

    return validationObject;
};

export const executeValidationAndReturnFormElement = (updatedRAFForm, raffields: RAFField[], id, name, value) => {
    let filteredFields = raffields.find(item => item.Name === id);
    if (filteredFields !== null && filteredFields !== undefined) {
        let formElement: RAFField = filteredFields;

        if (value !== null && value !== undefined) {
            formElement.Value = value;
        }
        //else {
        //    formElement.Value = formElement.EditorType === RAFEditorType.DateEditor || formElement.EditorType === RAFEditorType.DateTimeEditor ? event : event.target.value;
        //}
        formElement.Touched = true;

        const validationResponse = checkValidity(value, formElement.Required, formElement.Length);
        formElement.Valid = validationResponse.isValid;
        formElement.ErrorMessage = validationResponse.errorMessage;

        return formElement;
    }
};

export const checkAndSetValidity = (formElement: RAFField) => {
    if (formElement.Value !== null && formElement.Value !== undefined) {
    }
    else if (formElement.DefaultValue !== null && formElement.DefaultValue !== undefined) {
        formElement.Value = formElement.DefaultValue;
    }

    const validationResponse = checkValidity(formElement.Value, formElement.Required, formElement.Length);
    formElement.Valid = validationResponse.isValid;
    formElement.ErrorMessage = validationResponse.errorMessage;
    return formElement;
};

export const countInvalidElements = (raffields: RAFField[]) => {
    let countInvalidElements = 0;
    for (let element in raffields) {
        if (raffields[element].Name !== null && raffields[element].Name !== undefined && raffields[element].Name !== "") {
            if (!raffields[element].Valid) {
                countInvalidElements = countInvalidElements + 1;
                break;
            }
        }
    }
    return countInvalidElements;
};

export const getArrayTypeName = (item: RAFField[] | RAFSectionFields[]) => {
    if (item.length > 0) {
        if (item[0] instanceof RAFField) {
            return RAFField.name;
        }
        else if (item[0] instanceof RAFSectionFields) {
            return RAFSectionFields.name;
        }
    }
    return "";
}

export const getRAFFieldsFromObject = (obj): RAFField[] => {
    const rafFields: RAFField[] = [];
    for (let key in obj) {
        if (obj[key] instanceof RAFEntityBase) {
            let objFields = getRAFFieldsFromObject(obj[key] as RAFEntityBase);
            rafFields.push(...objFields);
        }
        else if (obj[key] !== null && Array.isArray(obj[key]) && getArrayTypeName(obj[key]) === RAFField.name) {
            let objFields = obj[key] as RAFField[];
            objFields.forEach(element => {
                rafFields.push(element);
            });
        }
    }
    return rafFields;
};

export const getFieldNameFromFullName = (fullName: string): string => {
    let retVal: string = fullName;
    if (fullName.indexOf(".") > 0) {
        retVal = fullName.substring(fullName.indexOf(".") + 1);
    }
    return retVal;
};

export const updateFieldInForm = (employeeForm1, updatedField: RAFField) => {
    //employeeForm1[updatedField.Name] = updatedField.Value;
    employeeForm1[getFieldNameFromFullName(updatedField.Name)] = updatedField.Value;
    if (employeeForm1.raffields !== null && employeeForm1.raffields !== undefined) {
        let objIndex = employeeForm1.raffields.findIndex(e => e.Name === updatedField.Name);
        if (objIndex > 0) {
            employeeForm1.raffields[objIndex] = updatedField;
        }
    }

    if (employeeForm1.rafSectionFields !== null && employeeForm1.rafSectionFields !== undefined) {
        employeeForm1.rafSectionFields.forEach((a, index) => {
            if (employeeForm1.rafSectionFields[index].fields !== null && employeeForm1.rafSectionFields[index].fields.length > 0) {
                let objIndex = employeeForm1.rafSectionFields[index].fields.findIndex(e => e.Name === updatedField.Name);
                if (objIndex > 0) {
                    employeeForm1.rafSectionFields[index].fields[objIndex] = updatedField;
                }
            }
        });
    }
    return employeeForm1;
};


export const updateValueInForm = (employeeForm1, id: string, name, value) => {
    for (let key in employeeForm1) {
        if (key.toLowerCase() === getFieldNameFromFullName(id).toLowerCase()) {
            employeeForm1[key] = value;
        }
    }
    for (let key in employeeForm1) {
        if (employeeForm1[key] !== null && employeeForm1[key] instanceof RAFEntityBase) {
            employeeForm1[key] = updateValueInForm(employeeForm1[key], id, name, value);
        }
    }
    for (let key in employeeForm1) {
        if (employeeForm1[key] !== null && Array.isArray(employeeForm1[key]) && getArrayTypeName(employeeForm1[key]) === RAFField.name) {
            let updatedField = executeValidationAndReturnFormElement(employeeForm1, employeeForm1[key], id, name, value);
            if (updatedField !== null && updatedField !== undefined && updatedField.Name !== "") {
                //let objIndex = employeeForm1.raffields.findIndex(e => e.Name === updatedField.Name);
                //if (objIndex > 0) {
                //    employeeForm1.raffields[objIndex] = updatedField;
                //}
                let objIndex = employeeForm1[key].findIndex(e => e.Name === updatedField.Name);
                if (objIndex > 0) {
                    employeeForm1[key][objIndex] = updatedField;
                }
            }
        }
    }
    for (let key in employeeForm1) {
        if (employeeForm1[key] !== null && Array.isArray(employeeForm1[key]) && getArrayTypeName(employeeForm1[key]) === RAFSectionFields.name) {
            employeeForm1[key].forEach((a, index) => {
                if (employeeForm1[key][index].fields !== null && employeeForm1[key][index].fields.length > 0) {
                    let updatedField = executeValidationAndReturnFormElement(employeeForm1[key][index], employeeForm1[key][index].fields, id, name, value);
                    if (updatedField !== null && updatedField !== undefined && updatedField.Name !== "") {
                        let objIndex = employeeForm1[key][index].fields.findIndex(e => e.Name === updatedField.Name);
                        if (objIndex > 0) {
                            employeeForm1.rafSectionFields[index].fields[objIndex] = updatedField;
                        }
                    }
                }
            });
        }
    }
    return employeeForm1;
};

export function createInstance<T>(type: { new(): T; }): T {
    return new type();
}
